import Box from '@mui/material/Box';
import { useInviteToTestFromSite } from 'src/hooks/useInviteToTestFromSite';

import { RequestHandler } from '../requestHandler/requestHandler';
import { InviteToTestHeader } from './inviteToTestHeader';
import { style } from './style';
import { InviteTestInfo } from './TestInfo';

export const InviteToTestContainerFromSite = () => {
  const { test, loading, errorSetChoiceInvitedCandidate, handleSubmitAccept, handleSubmitReject } =
    useInviteToTestFromSite();

  const title = `${test?.brand?.name} - ${test?.styleDescription}`;

  return (
    <Box sx={style.container}>
      <RequestHandler
        loading={loading || !test}
        errorMessage={errorSetChoiceInvitedCandidate?.message}
      >
        <InviteToTestHeader
          text={title}
          inviteAccept={handleSubmitAccept}
          inviteReject={handleSubmitReject}
          testData={test?.testerOverallStatus}
        />
        {test?.testerOverallStatus === 'PENDING' && (
          <Box
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '24px',
              backgroundColor: '#F8F8F8',
              padding: '8px 12px',
              marginBottom: '20px',
            }}
          >
            Your test enrollment is pending and we’ll be in touch soon.
          </Box>
        )}
        <InviteTestInfo values={test} />
      </RequestHandler>
    </Box>
  );
};
