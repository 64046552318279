import * as Yup from 'yup';

const ADDRESS_SCHEMA = Yup.object().shape({
  shipPreference: Yup.string().required(),
  postalCode: Yup.string().required(),
  addressOne: Yup.string().required(),
  addressTwo: Yup.string(),
  country: Yup.object().required(),
  city: Yup.string().required(),
  state: Yup.object().required(),
});

export default ADDRESS_SCHEMA;
