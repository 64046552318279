import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
// eslint-disable-next-line object-curly-newline
import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useOnClickOutside from 'src/hooks/useClickOutside';
import { rightPanel } from 'src/styles';

import { CheckIcon } from '../icons/CheckIcon';
import { containerHover, detailSelect, selectMenu } from './styles';

export const DetailSelect = ({
  value,
  name,
  possibleValues,
  handleChange,
  sxGender,
  menuTextCapitalize,
  selectField,
  submitOnValueChange = false,
  placeholder = 'none',
  iconComponent,
  alwaysShown = false,
  defaultOpen = true,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const { initialValues, handleSubmit, isSubmitting } = useFormikContext();

  const isTheSameValue =
    initialValues[name] === value || typeof initialValues[name] === 'undefined';

  useEffect(() => {
    if (!isTheSameValue && !isEditable && submitOnValueChange) {
      if (!isSubmitting) handleSubmit();
    }
  }, [isTheSameValue, isEditable, submitOnValueChange, isSubmitting, handleSubmit]);

  const toggle = useCallback(() => {
    if (!alwaysShown) {
      setIsEditable((prev) => !prev);
    }
  }, [alwaysShown]);

  const selectRef = useRef();

  useOnClickOutside(selectRef, toggle);

  const renderValue = useMemo(() => {
    const stringToValue = () => {
      const found =
        possibleValues?.find((item) =>
          typeof item === 'object' ? item.value === value : item === value,
        ) || value;

      return typeof found === 'object' ? found.title : found;
    };

    switch (typeof value) {
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'string':
        if (value !== 'none') {
          return stringToValue(value);
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return <Box color='#444444'>{placeholder}</Box>;
    }
  }, [value, possibleValues, placeholder]);

  const onChange = useCallback(
    (e) => {
      handleChange(e);
      toggle();
    },
    [handleChange, toggle],
  );

  if (!isEditable && !alwaysShown) {
    return (
      <Typography onClick={toggle} sx={[sxGender || detailSelect, containerHover]}>
        {renderValue}
      </Typography>
    );
  }

  return (
    <Select
      ref={selectRef}
      sx={[rightPanel.select, selectField]}
      name={name}
      onChange={onChange}
      value={value || placeholder}
      MenuProps={{
        keepMounted: true,
        disablePortal: true,
        PaperProps: {
          sx: selectMenu,
        },
      }}
      defaultOpen={defaultOpen}
      IconComponent={iconComponent}
    >
      <MenuItem disabled value={placeholder}>
        {placeholder}
      </MenuItem>
      {possibleValues?.map((item) => {
        if (typeof item === 'object') {
          return (
            <MenuItem value={item.value} key={item.value} sx={rightPanel.selectItem}>
              {value === item.value && (
                <Box className='check-icon' mr={3}>
                  <CheckIcon />
                </Box>
              )}
              {item.title}
            </MenuItem>
          );
        }

        return (
          <MenuItem
            value={item}
            key={item}
            sx={{
              ...menuTextCapitalize,
              ...rightPanel.selectItem,
            }}
          >
            {value === item && (
              <Box className='check-icon' mr={3}>
                <CheckIcon />
              </Box>
            )}
            {item}
          </MenuItem>
        );
      })}
    </Select>
  );
};

DetailSelect.propTypes = {
  value: oneOfType([string, number, bool]),
  name: string,
  possibleValues: instanceOf(Object),
  handleChange: func,
  label: string,
  sxGender: instanceOf(Object),
  menuTextCapitalize: instanceOf(Object),
  selectField: instanceOf(Object),
  submitOnValueChange: bool,
};
