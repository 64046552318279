import { useNavigate } from 'react-router-dom';
import { InviteStatus } from 'src/constants/constants';

import { useSetChoiceInvitedCandidate } from './useMutations';
import { useGetMyTestById } from './useQueries';

export const useInviteToTestFromSite = () => {
  const navigate = useNavigate();

  const { test } = useGetMyTestById();

  const {
    handleSetChoice,
    loading,
    error: errorSetChoiceInvitedCandidate,
  } = useSetChoiceInvitedCandidate();

  const handleSubmitAccept = async () => {
    handleSetChoice(test?.id, InviteStatus.ACCEPTED);
    navigate('/tests');
  };
  const handleSubmitReject = async () => {
    handleSetChoice(test?.id, InviteStatus.DECLINED);
    navigate('/tests');
  };

  const value = {
    test,
    loading,
    errorSetChoiceInvitedCandidate,
    handleSubmitAccept,
    handleSubmitReject,
  };

  return value;
};
