import Box from '@mui/material/Box';
import InseamImg from 'src/assets/images/inseam-img.png';

import { GeneralModal } from './GeneralModal';

const description = [
  'Stand naturally: Stand upright with your arms relaxed at your sides.',
  'Position the tape: Place the measuring tape at the highest point of the inner thigh (near the crotch). Extend the measuring tape down along the inner leg.',
  'Take the measurement: Make sure the tape is snug but not tight. Measure to the point where the hem of the pants is desired, typically just above the ankle bone.',
];

export const InseamModal = ({ open, toggleModal }) => {
  return (
    <Box>
      <GeneralModal
        open={open}
        toggleModal={toggleModal}
        title='How to Measure Your INSeam'
        src={InseamImg}
        description={description}
        helperText='Tip: It’s often more accurate to have another person take your measurements.'
      />
    </Box>
  );
};
