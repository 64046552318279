import { Button, InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { EditIcon } from 'src/assets/icons';
import { useToasts, useUpdateProfile } from 'src/hooks';
import useOnClickOutside from 'src/hooks/useClickOutside';

import { CloseIcon } from '..';
import { userNameSchema } from './schema';
import { styles } from './styles';

export const UserName = ({ user }) => {
  const fullNameRef = useRef(null);

  const [editNameCheck, setEditNameCheck] = useState(false);

  const [updateProfile] = useUpdateProfile();
  const { ErrorMessage } = useToasts();

  const handleUpdateProfile = (data, resetForm) =>
    updateProfile({
      variables: {
        input: data,
      },
      onError: () => {
        resetForm();
        ErrorMessage('Error. Please, try again.');
      },
    });

  const handleUpdateTester = (data, resetForm) => {
    const { firstName, lastName } = data;

    const dataVariable = {
      firstName,
      lastName,
    };

    handleUpdateProfile(dataVariable, resetForm);
  };

  const onSubmitForm = async (data, resetForm) => {
    handleUpdateTester(data, resetForm);
    setEditNameCheck(false);
  };

  const handleEditName = () => {
    setEditNameCheck(true);
  };

  const handleCancel = () => {
    setEditNameCheck(false);
  };

  useOnClickOutside(fullNameRef, handleCancel);

  return (
    <Box sx={styles.userNameWrapper} ref={fullNameRef}>
      {editNameCheck ? (
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
          }}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => onSubmitForm(values, resetForm)}
          validationSchema={userNameSchema}
        >
          {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => {
            const handleClearField = (fieldName) => () => {
              setFieldValue(fieldName, '');
            };

            return (
              <Form className='userNameForm'>
                <Box>
                  <TextField
                    size='small'
                    name='firstName'
                    value={values.firstName}
                    onInput={handleChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    placeholder='Your First Name'
                    sx={styles.firstNameInput}
                    InputProps={{
                      endAdornment: values.firstName && (
                        <InputAdornment
                          position='end'
                          onClick={handleClearField('firstName')}
                          sx={styles.button.close}
                        >
                          <CloseIcon />
                        </InputAdornment>
                      ),
                    }}
                    onBlur={handleBlur}
                  />
                  <TextField
                    size='small'
                    name='lastName'
                    value={values.lastName}
                    onInput={handleChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    placeholder='Your Last Name'
                    sx={styles.lastNameInput}
                    InputProps={{
                      endAdornment: values.lastName && (
                        <InputAdornment
                          position='end'
                          onClick={handleClearField('lastName')}
                          sx={styles.button.close}
                        >
                          <CloseIcon />
                        </InputAdornment>
                      ),
                    }}
                    onBlur={handleBlur}
                  />
                </Box>

                <Typography role='button' onClick={handleCancel} sx={styles.button.cancel}>
                  Cancel
                </Typography>
                <Button type='submit' sx={styles.button.submit}>
                  Ok
                </Button>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <>
          {user.firstName === null || user.lastName === null ? (
            <Typography
              variant='h4'
              sx={{
                color: '#9C9C9C',
              }}
            >
              Add name
            </Typography>
          ) : (
            <Typography variant='h4'>
              {user.firstName} {user.lastName}
            </Typography>
          )}
          <Button sx={styles.editBtn} onClick={handleEditName}>
            <EditIcon />
          </Button>
        </>
      )}
    </Box>
  );
};
