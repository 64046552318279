import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { CloseIcon } from 'src/components';
import { Flex, StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { getShoeSizeOptions } from 'src/components/testerDetail/tabs/getShoeSizeOptions';
import { months, years } from 'src/constants/constants';
import { useGetTesterValues } from 'src/hooks';
import { testerCreationForm } from 'src/styles';
import { daysInMonth, getValueDay } from 'src/utils/daysInMonth';
import {
  convertCmToFeetInches,
  convertFeetInchesToCm,
  convertKgToLbs,
  convertLbsToKg,
  handleCommaSeparatedValues,
  handleSetMultipleValues,
  handleShoeSizeValue,
} from 'src/utils/helpers';

import { styles } from '../styles';

export const Details = ({ handleClear }) => {
  const possibleValues = useGetTesterValues();

  const { values = {}, errors = {}, setFieldValue, handleChange } = useFormikContext();

  const { topSizeOptions, bottomSizeOptions, shoeSizeOptions, shoeWidth, genders } = possibleValues;

  const renderMonthValue = (val) => {
    if (val.length) {
      return months.find((item) => item.value === val).label;
    }
    return val;
  };

  const handleMonth = (e) => {
    const index = months.findIndex((item) => item.value === e.target.value);

    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('month', index).toDate();
    } else {
      newDate = dayjs().set('month', index).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  const handleDay = (e) => {
    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('date', e.target.value).toDate();
    } else {
      newDate = dayjs().set('date', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  const handleYear = (e) => {
    let newDate;

    if (values.birthdate) {
      newDate = dayjs(values.birthdate).set('year', e.target.value).toDate();
    } else {
      newDate = dayjs().set('year', e.target.value).toDate();
    }

    setFieldValue('birthdate', format(newDate, 'yyyy-MM-dd'));
  };

  const handleChangeLbs = (e) => {
    handleChange(e);
    setFieldValue('weightKgs', convertLbsToKg(e.target.value));
  };
  const handleChangeKg = (e) => {
    handleChange(e);
    setFieldValue('weightLbs', convertKgToLbs(e.target.value));
  };

  const handleChangeFeet = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: e.target.value,
        inches: values.heightImperialInch,
      }),
    );
  };
  const handleChangeInches = (e) => {
    handleChange(e);
    setFieldValue(
      'heightMetric',
      convertFeetInchesToCm({
        feet: values.heightImperialFeet,
        inches: e.target.value,
      }),
    );
  };

  const handleChangeCmToFeetInches = (e) => {
    handleChange(e);

    const data = convertCmToFeetInches({
      cm: e.target.value,
    });

    setFieldValue('heightImperialFeet', data.feet);
    setFieldValue('heightImperialInch', data.inches);
  };

  const handleChangeInchesToCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertFeetInchesToCm({
        inches: e.target.value,
      }),
    );
  };

  const handleChangeCm = (pairName) => (e) => {
    handleChange(e);
    setFieldValue(
      pairName,
      convertCmToFeetInches({
        cm: e.target.value,
        inInches: true,
      }),
    );
  };

  return (
    <Box
      sx={{
        width: '75%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <StyledLabel label='Birthdate'>
          <Flex gap={1}>
            <StyledSelect
              name='birthdate'
              onChange={handleMonth}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='MM'
              value={values.birthdate && dayjs(values.birthdate).get('month').toString()}
              renderValue={renderMonthValue}
            >
              {months.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.option}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item.option}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleDay}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='DD'
              value={getValueDay(values.birthdate)}
            >
              {daysInMonth(values.birthdate).map((date) => (
                <MenuItem
                  value={date}
                  key={date}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {date}
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              name='birthdate'
              onChange={handleYear}
              error={!!errors.gender}
              helperText={errors.gender}
              placeholder='YYYY'
              value={dayjs(values.birthdate).get('year')}
            >
              {years.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </StyledSelect>
          </Flex>
        </StyledLabel>
      </Box>
      <StyledLabel label='Gender'>
        <StyledSelect
          name='gender'
          onChange={handleChange}
          error={!!errors.gender}
          helperText={errors.gender}
          placeholder='Select Gender'
          value={values.gender}
        >
          {genders.map((item) => (
            <MenuItem
              value={item}
              key={item}
              sx={{
                textTransform: 'capitalize',
              }}
            >
              {item}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Weight (lbs)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='weightLbs'
            onInput={handleChangeLbs}
            value={values.weightLbs}
            error={!!errors.weightLbs}
            helperText={errors.weightLbs}
            placeholder='Lbs'
            InputProps={{
              endAdornment: values.weightLbs && (
                <InputAdornment position='end' onClick={handleClear('weightLbs')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Weight (kgs)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='weightKgs'
            onInput={handleChangeKg}
            value={values.weightKgs}
            error={!!errors.weightKgs}
            helperText={errors.weightKgs}
            placeholder='Kgs'
            InputProps={{
              endAdornment: values.weightKgs && (
                <InputAdornment position='end' onClick={handleClear('weightKgs')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <StyledLabel label='Height (Imperial)'>
          <Flex gap={1}>
            <TextField
              type='number'
              name='heightImperialFeet'
              size='small'
              sx={testerCreationForm.input}
              onInput={handleChangeFeet}
              value={values.heightImperialFeet}
              error={!!errors.heightImperialFeet}
              helperText={errors.heightImperialFeet}
              placeholder='Feet'
              InputProps={{
                endAdornment: !!values.heightImperialFeet && (
                  <InputAdornment position='end' onClick={handleClear('heightImperialFeet')}>
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type='number'
              name='heightImperialInch'
              size='small'
              sx={testerCreationForm.input}
              onInput={handleChangeInches}
              value={values.heightImperialInch}
              error={!!errors.heightImperialInch}
              helperText={errors.heightImperialInch}
              placeholder='Inches'
              InputProps={{
                endAdornment: !!values.heightImperialInch && (
                  <InputAdornment position='end' onClick={handleClear('heightImperialInch')}>
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Flex>
        </StyledLabel>
        <StyledLabel label='Height (Metric)'>
          <TextField
            type='number'
            name='heightMetric'
            size='small'
            sx={testerCreationForm.input}
            onInput={handleChangeCmToFeetInches}
            value={values.heightMetric}
            error={!!errors.heightMetric}
            helperText={errors.heightMetric}
            placeholder='cm'
            InputProps={{
              endAdornment: !!values.heightMetric && (
                <InputAdornment position='end' onClick={handleClear('heightMetric')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      {/* Всі селекти з розмірами нижче реалізовані через групування (<ListSubheader>). Розподіл на чоловічі та жіночі розміри та US розміри */}
      <StyledLabel label='General Shoe Size'>
        <StyledSelect
          name='generalShoeSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          error={!!errors.generalShoeSizes}
          helperText={errors.generalShoeSizes}
          placeholder='Select Shoe Size'
          value={values.generalShoeSizes}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          multiple
          wrapItems
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Running Shoe Size'>
        <StyledSelect
          name='runningShoeSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          error={!!errors.runningShoeSizes}
          helperText={errors.runningShoeSizes}
          placeholder='Select Shoe Size'
          value={values.runningShoeSizes}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          wrapItems
          multiple
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Hiking Boot Size'>
        <StyledSelect
          name='hikingShoeSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          error={!!errors.hikingShoeSizes}
          helperText={errors.hikingShoeSizes}
          placeholder='Select Boot Size'
          value={values.hikingShoeSizes}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          wrapItems
          multiple
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Sandal Size'>
        <StyledSelect
          name='sandalShoeSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          error={!!errors.sandalShoeSizes}
          helperText={errors.sandalShoeSizes}
          placeholder='Select Sandal Size'
          value={values.sandalShoeSizes}
          renderValue={handleShoeSizeValue(shoeSizeOptions)}
          wrapItems
          multiple
        >
          {getShoeSizeOptions({
            possibleValues,
          })}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Shoe Width'>
        <StyledSelect
          name='shoeWidth'
          onChange={handleSetMultipleValues(setFieldValue)}
          error={!!errors.shoeWidth}
          helperText={errors.shoeWidth}
          placeholder='Select Width'
          value={values.shoeWidth}
          renderValue={handleCommaSeparatedValues}
          wrapItems
          multiple
        >
          <ListSubheader
            style={{
              ...styles.label,
              height: '35px',
            }}
          >
            Select all that apply
          </ListSubheader>
          {shoeWidth.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Additional Shoe Size Info'>
        <TextField
          sx={testerCreationForm.input}
          minRows={4}
          size='small'
          name='shoeSizeAdditionalInfo'
          multiline
          onInput={handleChange}
          value={values.shoeSizeAdditionalInfo}
          error={!!errors.shoeSizeAdditionalInfo}
          helperText={errors.shoeSizeAdditionalInfo}
          placeholder='Eg. My right foot is one size bigger than my left'
        />
      </StyledLabel>
      <StyledLabel label='Top Size'>
        <StyledSelect
          name='topSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          value={values.topSizes}
          error={!!errors.topSizes}
          helperText={errors.topSizes}
          placeholder='Select Top Size'
          renderValue={handleShoeSizeValue(topSizeOptions)}
          wrapItems
          multiple
        >
          <ListSubheader style={styles.label}>Select all that apply</ListSubheader>
          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Men’s</div>
          </ListSubheader>
          {topSizeOptions.man?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Women’s</div>
          </ListSubheader>
          {topSizeOptions.woman.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </StyledLabel>
      <StyledLabel label='Bottom Size'>
        <StyledSelect
          name='bottomSizes'
          onChange={handleSetMultipleValues(setFieldValue)}
          value={values.bottomSizes}
          error={!!errors.bottomSizes}
          helperText={errors.bottomSizes}
          placeholder='Select Bottom Size'
          renderValue={handleShoeSizeValue(bottomSizeOptions)}
          wrapItems
          multiple
        >
          <ListSubheader style={styles.label}>Select all that apply</ListSubheader>
          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Women’s</div>
          </ListSubheader>
          {bottomSizeOptions.woman?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownSubTitleText}>US</div>
          </ListSubheader>
          {bottomSizeOptions.womanUS?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownTitleText}>Men’s</div>
          </ListSubheader>
          {bottomSizeOptions.man?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}

          <ListSubheader style={styles.dropdownTitleBox}>
            <div style={styles.dropdownSubTitleText}>US</div>
          </ListSubheader>
          {bottomSizeOptions.manUS?.map((size) => {
            return (
              <MenuItem value={size.id} key={size.id}>
                {size.name}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </StyledLabel>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Chest Size (US)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='chestInches'
            onInput={handleChangeInchesToCm('chestCm')}
            value={values.chestInches}
            error={!!errors.chestInches}
            helperText={errors.chestInches}
            placeholder='Inches'
            InputProps={{
              endAdornment: !!values.chestInches && (
                <InputAdornment position='end' onClick={handleClear('chestInches')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Chest Size (cm)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='chestCm'
            onInput={handleChangeCm('chestInches')}
            value={values.chestCm}
            error={!!errors.chestCm}
            helperText={errors.chestCm}
            placeholder='cms'
            InputProps={{
              endAdornment: !!values.chestCm && (
                <InputAdornment position='end' onClick={handleClear('chestCm')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Waist at Navel (US)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtNavelInches'
            onInput={handleChangeInchesToCm('waistAtNavelCm')}
            value={values.waistAtNavelInches}
            error={!!errors.waistAtNavelInches}
            helperText={errors.waistAtNavelInches}
            placeholder='Inches'
            InputProps={{
              endAdornment: !!values.waistAtNavelInches && (
                <InputAdornment position='end' onClick={handleClear('waistAtNavelInches')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Waist at Navel (cm)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtNavelCm'
            onInput={handleChangeCm('waistAtNavelInches')}
            value={values.waistAtNavelCm}
            error={!!errors.waistAtNavelCm}
            helperText={errors.waistAtNavelCm}
            placeholder='cms'
            InputProps={{
              endAdornment: !!values.waistAtNavelCm && (
                <InputAdornment position='end' onClick={handleClear('waistAtNavelCm')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Waist at Pants (US)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtPantsInches'
            onInput={handleChangeInchesToCm('waistAtPantsCm')}
            value={values.waistAtPantsInches}
            error={!!errors.waistAtPantsInches}
            helperText={errors.waistAtPantsInches}
            placeholder='Inches'
            InputProps={{
              endAdornment: !!values.waistAtPantsInches && (
                <InputAdornment position='end' onClick={handleClear('waistAtPantsInches')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Waist at Pants (cm)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='waistAtPantsCm'
            onInput={handleChangeCm('waistAtPantsInches')}
            value={values.waistAtPantsCm}
            error={!!errors.waistAtPantsCm}
            helperText={errors.waistAtPantsCm}
            placeholder='cms'
            InputProps={{
              endAdornment: !!values.waistAtPantsCm && (
                <InputAdornment position='end' onClick={handleClear('waistAtPantsCm')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Hips (US)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='hipsInches'
            onInput={handleChangeInchesToCm('hipsCm')}
            value={values.hipsInches}
            error={!!errors.hipsInches}
            helperText={errors.hipsInches}
            placeholder='Inches'
            InputProps={{
              endAdornment: !!values.hipsInches && (
                <InputAdornment position='end' onClick={handleClear('hipsInches')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Hips (cm)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='hipsCm'
            onInput={handleChangeCm('hipsInches')}
            value={values.hipsCm}
            error={!!errors.hipsCm}
            helperText={errors.hipsCm}
            placeholder='cms'
            InputProps={{
              endAdornment: !!values.hipsCm && (
                <InputAdornment position='end' onClick={handleClear('hipsCm')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.twoColumns}>
        <StyledLabel label='Inseam (US)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='inseamInches'
            onInput={handleChangeInchesToCm('inseamCm')}
            value={values.inseamInches}
            error={!!errors.inseamInches}
            helperText={errors.inseamInches}
            placeholder='Inches'
            InputProps={{
              endAdornment: !!values.inseamInches && (
                <InputAdornment position='end' onClick={handleClear('inseamInches')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
        <StyledLabel label='Inseam (cm)'>
          <TextField
            type='number'
            sx={testerCreationForm.input}
            size='small'
            name='inseamCm'
            onInput={handleChangeCm('inseamInches')}
            value={values.inseamCm}
            error={!!errors.inseamCm}
            helperText={errors.inseamCm}
            placeholder='cms'
            InputProps={{
              endAdornment: !!values.inseamCm && (
                <InputAdornment position='end' onClick={handleClear('inseamCm')}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledLabel>
      </Box>

      <StyledLabel label='Additional Clothing Info'>
        <TextField
          sx={testerCreationForm.input}
          minRows={4}
          size='small'
          name='clothingSizeAdditionalInfo'
          multiline
          onInput={handleChange}
          value={values.clothingSizeAdditionalInfo}
          error={!!errors.clothingSizeAdditionalInfo}
          helperText={errors.clothingSizeAdditionalInfo}
          placeholder='Eg. I can usually wear a large or extra large'
        />
      </StyledLabel>
    </Box>
  );
};

Details.propTypes = {
  handleClear: func,
};
