export const styles = {
  styledSubmitButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      borderRadius: '4px',
      background: '#000000',
      color: '#ffffff',
      padding: '4px 16px',
      '&.Mui-disabled': {
        backgroundColor: '#DEDEDE',
        color: '#FFFFFF',
      },
    },
  },
  styledCancelButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      marginRight: '12px',
      height: '28px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#676767',
    },
  },
  styledRemoveButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      backgroundColor: 'transparent',
      color: '#DE4141',
      marginRight: '12px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  shipPreferenceSelect: {
    '.MuiMenuItem-root .check-icon': {
      display: 'initial !important',
    },
    '&.MuiInputBase-root': {
      '& > div[aria-expanded=true]': {
        '& ~ div .chevron-down-icon': {
          transform: 'rotate(180deg)',
        },
      },
      '&.MuiOutlinedInput-root': {
        maxWidth: '180px',
        height: '40px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '0px',
        '& .MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':
          {
            padding: '10px 12px',
            position: 'absolute',
            maxWidth: '180px',
            boxSizing: 'border-box',
          },
      },
      '& .chevron-down-icon': {
        margin: '0px 14px 0px 9px',
      },
    },
  },
  addressSelectWrapper: {
    '& .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.MuiFormControl-root.MuiTextField-root':
      {
        maxWidth: '180px',
      },
    '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root':
      {
        fontSize: '14px',
        padding: '10px 12px',
        '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          padding: '0px',
        },
      },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
      {
        padding: '0px',
        marginRight: '2px',
        '&:hover': {
          background: 'transparent',
        },
        '& > div': {
          display: 'flex',
        },
      },
  },
  textFieldWrapper: {
    maxWidth: '400px',
    '& .MuiInputBase-root.MuiOutlinedInput-root input': {
      padding: '10px 12px',
      fontSize: '14px',
    },
  },
  confirmModal: {
    '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root': {
      maxWidth: '317px',
      width: '100%',
      minWidth: '317px',
    },
    '& .agree-btn': {
      backgroundColor: '#ffffff',
      color: '#000000',
      padding: '4px 16px',
    },
  },
};
