import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { testerCreationForm } from 'src/styles';

import { SelectYesOrNot } from '../StyledComponents';

export const About = () => {
  const { values = {}, errors = {}, touched = {}, handleChange, handleBlur } = useFormikContext();

  return (
    <Box
      sx={{
        width: '75%',
      }}
    >
      <StyledLabel label='Tell us about yourself as it relates to outdoor product testing'>
        <TextField
          sx={testerCreationForm.input}
          minRows={6}
          size='small'
          name='relatesOutdoorProductTestingDescription'
          multiline
          value={values.relatesOutdoorProductTestingDescription}
          onBlur={handleBlur}
          onInput={handleChange}
          error={
            touched.relatesOutdoorProductTestingDescription &&
            !!errors.relatesOutdoorProductTestingDescription
          }
          helperText={
            touched.relatesOutdoorProductTestingDescription &&
            errors.relatesOutdoorProductTestingDescription
          }
          placeholder='Eg. I travel to Alaska every summer to teach polar bear tennis and ski the barren tundra.'
        />
      </StyledLabel>
      <StyledLabel label='How did you hear about us?'>
        <TextField
          sx={testerCreationForm.input}
          minRows={4}
          size='small'
          name='didYouHearAboutUs'
          multiline
          onBlur={handleBlur}
          value={values.didYouHearAboutUs}
          onInput={handleChange}
          error={touched.didYouHearAboutUs && !!errors.didYouHearAboutUs}
          helperText={touched.didYouHearAboutUs && errors.didYouHearAboutUs}
          placeholder='Eg. My best friend’s cousin’s friend’s neighbor told me to check out the MERG crew.'
        />
      </StyledLabel>
      <StyledLabel label='Do you work for an outdoor company?'>
        <SelectYesOrNot
          name='isWorkForAnOutdoorCompany'
          onChange={handleChange}
          errors={errors}
          values={values}
        />
      </StyledLabel>
      <StyledLabel label='What is your Occupation?'>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='occupation'
          onInput={handleChange}
          onBlur={handleBlur}
          value={values.occupation}
          error={touched.occupation && !!errors.occupation}
          helperText={touched.occupation && errors.occupation}
          placeholder='Enter occupation'
        />
      </StyledLabel>
      <StyledLabel label='Do you have a dog?'>
        <SelectYesOrNot name='isHaveDog' onChange={handleChange} errors={errors} values={values} />
      </StyledLabel>
      <StyledLabel label='Do you recreate with kids?'>
        <SelectYesOrNot
          name='isRecreateWithKids'
          onChange={handleChange}
          errors={errors}
          values={values}
        />
      </StyledLabel>
    </Box>
  );
};
