import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { instanceOf } from 'prop-types';
import { useCallback, useState } from 'react';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { mediaSectionStyles } from 'src/components/myTest/styles';
import { RemoveModal } from 'src/components/UI';
import { TestStatus } from 'src/constants/constants';
import {
  useEditTestMedia,
  useGetMyTestById,
  useRemoveTestMedia,
  useUploadTestMedia,
} from 'src/hooks';
import { theme } from 'src/theme';

import { MediaCard } from './MediaCard';

export const TesterMediaSection = ({ mediaList }) => {
  const [editTestMedia] = useEditTestMedia();
  const [removeTestMedia] = useRemoveTestMedia();
  const [uploadTestMedia] = useUploadTestMedia();
  const { test, refetch } = useGetMyTestById();

  const isTestClosed = test?.status === TestStatus.CLOSED;

  const [removedMedia, setRemovedMedia] = useState(null);
  const closeModal = useCallback(() => {
    setRemovedMedia(null);
  }, []);

  const handleUploadMedia = useCallback(({ target }) => {
    uploadTestMedia({
      variables: {
        file: target.files?.[0],
      },
      onCompleted: refetch,
    });
    // eslint-disable-next-line no-param-reassign
    target.value = null;
  }, []);

  const handleEditCaption = useCallback(
    ({ testMediaId, caption }) =>
      editTestMedia({
        variables: {
          testMediaId,
          input: {
            caption,
          },
        },
        onCompleted: refetch,
      }),
    [],
  );

  const handleRemoveMedia = (id) => () => {
    setRemovedMedia(id);
  };

  const acceptRemoveMedia = useCallback(
    () =>
      removeTestMedia({
        variables: {
          testMediaId: removedMedia,
        },
        onCompleted: () => {
          refetch();
          closeModal();
        },
      }),
    [removedMedia],
  );

  return (
    <Box>
      {isTestClosed || (
        <Flex sx={mediaSectionStyles.header}>
          <Box
            component='label'
            htmlFor='file'
            sx={[
              mediaSectionStyles.button.upload,
              mediaList?.length >= 10 && {
                backgroundColor: theme.colors.neutral.light.normal,
              },
            ]}
          >
            Upload
          </Box>
          <Typography fontSize={14} color={theme.colors.neutral.dark.mid}>
            10 Uploads Max
          </Typography>
        </Flex>
      )}
      {mediaList?.length >= 10 || (
        <Box component='input' type='file' id='file' hidden onChange={handleUploadMedia} />
      )}
      <Flex sx={mediaSectionStyles.mediaList}>
        {mediaList?.length ? (
          mediaList
            .map((media) => (
              <MediaCard
                isEditable={!isTestClosed}
                key={media.id}
                media={media}
                handleEditCaption={handleEditCaption}
                handleRemoveMedia={handleRemoveMedia}
              />
            ))
            .reverse()
        ) : (
          <Typography fontSize={14} color={theme.colors.neutral.dark.mid}>
            {isTestClosed ? 'There is no images' : 'Upload image or video'}
          </Typography>
        )}
      </Flex>
      <RemoveModal
        title='Are you sure you want to remove this image and caption?'
        open={!!removedMedia}
        callback={acceptRemoveMedia}
        closeModal={closeModal}
        itemId={removedMedia}
      />
    </Box>
  );
};

TesterMediaSection.propTypes = {
  mediaList: instanceOf(Array),
};
