import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { useGetTesterValues } from 'src/hooks';
import { testerCreationForm } from 'src/styles';
import { handleCommaSeparatedValues, handleSetMultipleValues } from 'src/utils/helpers';

import { styles } from './styles';

export const Activities = () => {
  const possibleValues = useGetTesterValues();

  const {
    values = {},
    errors = {},
    touched = {},
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const [otherActivities, setOtherActivities] = useState(values.otherActivities || []);

  const otherActivitiesRef = useRef([]);

  useEffect(() => {
    otherActivitiesRef.current = [...otherActivities];
  }, [otherActivities]);

  useEffect(
    () => () => {
      setFieldValue(
        'otherActivities',
        otherActivitiesRef.current.filter((item) => item.name.length && item.value !== ''),
      );
    },
    [],
  );

  const handleAddOtherActivity = useCallback(() => {
    setOtherActivities((prev) => [
      ...prev,
      {
        name: '',
        value: '',
        wantToTest: true,
      },
    ]);
  }, []);

  const handleChangeFrequency = useCallback(
    (value) => (e) => {
      handleChange(e);
      setFieldValue(`${value}IsWantToTest`, e.target.value !== 'NEVER');
    },
    [],
  );

  const handleChangeOtherActivity = useCallback(
    (index, value) => (e) => {
      setOtherActivities((prev) => {
        const array = [...prev];
        if (value === 'value') {
          array[index] = {
            ...array[index],
            [value]: e.target.value,
            wantToTest: e.target.value !== 'NEVER',
          };
        } else {
          array[index][value] = e.target.value;
        }
        return array;
      });
    },
    [],
  );

  const renderValue = (val) =>
    possibleValues.frequencyValues.find((item) => item.value === val)?.title;

  return (
    <Box
      sx={{
        mb: 4,
      }}
    >
      <Box sx={styles.activitiesWrapper}>
        <StyledLabel label='What type of gear would you like to test?'>
          <StyledSelect
            name='typeGearWouldTest'
            onChange={handleSetMultipleValues(setFieldValue)}
            error={touched.typeGearWouldTest && !!errors.typeGearWouldTest}
            helperText={touched.typeGearWouldTest && errors.typeGearWouldTest}
            placeholder='Select Gear'
            value={values.typeGearWouldTest}
            handleBlur={handleBlur}
            renderValue={handleCommaSeparatedValues}
            multiple
            wrapItems
          >
            {possibleValues.typeGearWouldTest.map((type) => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledLabel>
      </Box>
      <Flex>
        <StyledLabel label='How often do you participate in the following activities?' />
      </Flex>
      {possibleValues.activities.map(({ title, value }) => {
        const defaultValue = possibleValues.frequencyValues?.find(
          (freq) => freq.value === 'NEVER',
        )?.value;

        return (
          <Flex sx={testerCreationForm.activity.root} key={value}>
            <Box sx={styles.activitiesWrapper}>
              <StyledLabel label={title} direction='row' sx={testerCreationForm.activity.label}>
                <StyledSelect
                  name={value}
                  onChange={handleChangeFrequency(value)}
                  error={touched[value] && !!errors[value]}
                  value={values[value] || defaultValue}
                  helperText={touched[value] && errors[value]}
                  handleBlur={handleBlur}
                  placeholder='Select Frequency'
                  renderValue={renderValue}
                >
                  {possibleValues.frequencyValues?.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </StyledLabel>
            </Box>
          </Flex>
        );
      })}
      {otherActivities.map(({ name, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex sx={testerCreationForm.activity.root} key={index}>
          <Box sx={styles.activitiesWrapper}>
            <StyledLabel
              label='Other Activity'
              direction='row'
              key={value}
              sx={testerCreationForm.activity.label}
            >
              <Box>
                <TextField
                  sx={[
                    testerCreationForm.input,
                    {
                      width: '100%',
                    },
                  ]}
                  size='small'
                  onChange={handleChangeOtherActivity(index, 'name')}
                  value={name}
                  placeholder='Enter Activity'
                />
                <StyledSelect
                  onChange={handleChangeOtherActivity(index, 'value')}
                  value={value}
                  placeholder='Select Frequency'
                  sx={{
                    mt: 1,
                  }}
                  renderValue={renderValue}
                >
                  {possibleValues.frequencyValues?.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>
            </StyledLabel>
          </Box>
        </Flex>
      ))}
      <Button
        variant='outlined'
        sx={testerCreationForm.activity.button}
        onClick={handleAddOtherActivity}
      >
        Add Another Activity
      </Button>
    </Box>
  );
};
