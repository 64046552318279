import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { InfoIcon } from 'src/assets/icons';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';

import { detailsTabStyles } from './backDropStyles';
import { HipsModal } from './modal/HipsModal';

export const DetailsHips = ({ handleChangeInchesToCm, handleChangeCm, testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, toggleModal] = useState(false);

  const { values = {}, errors = {}, handleSubmit } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit();
    }
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
    setIsEditMode(false);
  };

  return (
    <>
      <Box>
        {isEditMode ? (
          <Box sx={styles.twoColumns}>
            <Backdrop
              open={isEditMode}
              invisible={false}
              onClick={closeAndSubmit}
              sx={detailsTabStyles.backDropStyle}
            />
            <StyledLabel label='Hips (in)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='hipsInches'
                onInput={handleChangeInchesToCm('hipsCm')}
                value={values.hipsInches}
                error={!!errors.hipsInches}
                helperText={errors.hipsInches}
                placeholder='Inches'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
            <StyledLabel label='Hips (cm)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='hipsCm'
                onInput={handleChangeCm('hipsInches')}
                value={values.hipsCm}
                error={!!errors.hipsCm}
                helperText={errors.hipsCm}
                placeholder='cms'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
          </Box>
        ) : (
          <Box onClick={changeViewBox}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <StyledLabel label='Hips' width='unset' sx={detailsTabStyles.styledLabelFont} />
              {testerProfile && (
                <Box
                  sx={{
                    '& svg': {
                      minWidth: '15px',
                      minHeight: '15px',
                      margin: '2px 0px 0px 6.5px',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleToggleModal}
                >
                  <InfoIcon />
                </Box>
              )}
            </Box>
            <Box sx={detailsTabStyles.styleForResultDetail}>
              {values.hipsInches !== null || values.hipsCm !== null ? (
                `${values.hipsInches || '0'}''
              (${values.hipsCm || '0'}cm)`
              ) : (
                <Box color='#444'>Enter hips size</Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <HipsModal open={open} toggleModal={handleToggleModal} />
    </>
  );
};

DetailsHips.propTypes = {
  handleChangeInchesToCm: func,
  handleChangeCm: func,
};
