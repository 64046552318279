import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  ScrollContainer,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';

import { CreateTesterForm } from './createTesterForm';
import { Footer } from './createTesterForm/Footer';
import { Header } from './createTesterForm/Header';
import { steps } from './createTesterForm/mock';
import { UploadCsvModal } from './UploadCsvModal';

const isValidStep = ({ requiredStepFields = [], errors }) => {
  return !Object.keys(errors).some((fieldName) => requiredStepFields.includes(fieldName));
};

export const CreateTester = ({ open, toggleModal, duplicate }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const formRef = useRef(null);

  const {
    handleSubmit,
    dirty,
    isValid,
    setFieldValue,
    setFieldError,
    initialValues: initValues,
    errors,
  } = useFormikContext();

  const REQUIRED_STEP_FIELDS = {
    0: [
      'fullName',
      'email',
      // 'weight',
      // 'phoneNumber',
      // 'isCallOrText',
      // 'addressOne',
      // 'city',
      // 'country',
      // 'state',
      // 'postalCode',
    ],
    1: [
      // 'birthdate'
    ],
    2: [
      // 'isWorkForAnOutdoorCompany',
      // 'typeGearWouldTest',
      // Temporary comment
      // ...activities.map((acitivity) => acitivity.value),
    ],
    3: [
      // 'relatesOutdoorProductTestingDescription', 'didYouHearAboutUs'
    ],
    4: [],
  };

  //  Finish button is disabled when there are errors in form fields or no fields where changed and duplicate data is not available
  const isFinishButtonDisabled = (!dirty && !duplicate) || !isValid;

  const isNextButtonDisabled =
    (!dirty && !duplicate) ||
    !isValidStep({
      requiredStepFields: REQUIRED_STEP_FIELDS[currentStep],
      errors,
    });

  useEffect(() => {
    formRef.current?.scrollIntoView();
  }, [currentStep, formRef]);

  const handleFileUpload = ({ target }) => {
    const [file] = target.files;
    // eslint-disable-next-line no-param-reassign
    target.files = null;

    setUploadedFile(file);
  };

  const handleClickPrev = () => {
    if (!currentStep) return false;
    return setCurrentStep(currentStep - 1);
  };

  const handleClickNext = () => {
    if (currentStep >= steps.length) return false;
    return setCurrentStep(currentStep + 1);
  };

  useEffect(() => () => !open && setUploadedFile(undefined), [open]);

  if (uploadedFile) {
    return <UploadCsvModal open={open} toggleModal={toggleModal} file={uploadedFile} />;
  }

  const handleClear = (name) => () => {
    setFieldValue(name, initValues[name]);
  };

  return (
    <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
      <StyledPaper>
        <Header
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleFileUpload={handleFileUpload}
        />
        <ScrollContainer>
          <Box ref={formRef}>
            <CreateTesterForm
              currentStep={currentStep}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              handleClear={handleClear}
            />
          </Box>
        </ScrollContainer>
        <Footer
          currentStep={currentStep}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          handleClickSubmit={handleSubmit}
          handleClickCancel={toggleModal}
          isNextButtonDisabled={isNextButtonDisabled}
          isFinishButtonDisabled={isFinishButtonDisabled}
        />
      </StyledPaper>
    </StyledModal>
  );
};

CreateTester.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
  tester: PropTypes.instanceOf(Object),
  duplicate: PropTypes.bool,
};
