import Box from '@mui/material/Box';
import ChestImg from 'src/assets/images/chest-img.png';

import { GeneralModal } from './GeneralModal';

const description = [
  'Use a flexible measuring tape: Fabric or vinyl is best. Metal tapes are too rigid. If you don’t have a flexible measuring tape, use string and after measuring, lay it flat and use a metal tape to measure it.',
  'Stand naturally: Stand upright with your arms relaxed at your sides.',
  'Position the tape: Wrap the tape around the fullest part of the chest, which is typically just under the armpits and around the shoulder blades, at the nipple line.',
  'Ensure the tape is level: It should be horizontal and lie flat against the body without sagging in the back.',
  'Take the measurement: Make sure the tape is snug but not tight, allowing enough room for comfort and movement. You should breathe normally and avoid holding your breath to ensure an accurate measurement.',
];

export const ChestModal = ({ open, toggleModal }) => {
  return (
    <Box>
      <GeneralModal
        open={open}
        toggleModal={toggleModal}
        title='How to Measure Your Chest'
        src={ChestImg}
        description={description}
        helperText='Tip: It’s often more accurate to have another person take your measurements.'
      />
    </Box>
  );
};
