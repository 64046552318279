import { Form, Formik } from 'formik';
import { useToasts, useUpdateProfile } from 'src/hooks';

import ADDRESS_SCHEMA from './address.schema';
import { AddressGeneralModal } from './AddressGeneralModal';
import PrimaryAddressModalContent from './PrimaryAddressModalContent';

export const PrimaryAddressModal = ({ open, toggleModal, initialValues }) => {
  const [updateProfile] = useUpdateProfile({
    successMessage: 'Primary address added',
  });
  const { ErrorMessage } = useToasts();

  const onSubmit = (data) => {
    updateProfile({
      variables: {
        input: {
          testerInfo: {
            shipPreference: data.shipPreference,
            postalCode: data.postalCode,
            addressOne: data.addressOne,
            addressTwo: data.addressTwo,
            countryId: data.country.id,
            country: data.country.name,
            city: data.city,
            stateId: data.state.id,
          },
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ADDRESS_SCHEMA}
      validateOnMount={true}
    >
      {({ handleSubmit, errors }) => {
        return (
          <Form>
            <AddressGeneralModal
              title='Primary Address'
              open={open}
              toggleModal={toggleModal}
              handleClickSubmit={handleSubmit}
              disabledSave={!!Object.keys(errors).length}
            >
              <PrimaryAddressModalContent />
            </AddressGeneralModal>
          </Form>
        );
      }}
    </Formik>
  );
};
