import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { DetailLabel } from 'src/components/UI';

import AddressSummary from './AddressSummary';
import { PrimaryAddressModal } from './modals/PrimaryAddressModal';
import { SecondaryAddressModal } from './modals/SecondaryAddressModal';
import { styles } from './styles';

export const AddressFieldsWrapper = ({ values: data = {} }) => {
  const [isPrimaryOpen, setPrimaryOpen] = useState(false);
  const [isSecondaryOpen, setSecondaryOpen] = useState(false);

  const initialValues = {
    shipPreference: data.shipPreference || 'FedEx/UPS',
    addressOne: data.addressOne ?? '',
    addressTwo: data.addressTwo ?? '',
    country: data.country,
    state: data.state,
    city: data.city,
    postalCode: data.postalCode,
  };

  const initialValuesSecondary = {
    shipPreference: data.secondaryAddress?.shipPreference || 'FedEx/UPS',
    addressOne: data.secondaryAddress?.addressOne ?? '',
    addressTwo: data.secondaryAddress?.addressTwo ?? '',
    country: data.secondaryAddress?.country,
    state: data.secondaryAddress?.state,
    city: data.secondaryAddress?.city,
    postalCode: data.secondaryAddress?.postalCode,
  };

  const toggle = () => setPrimaryOpen((prev) => !prev);

  const showPrimarySummary = !!(
    initialValues.shipPreference &&
    initialValues.addressOne &&
    initialValues.country &&
    initialValues.state &&
    initialValues.city &&
    initialValues.postalCode
  );

  const showSecondarySummary = !!(
    initialValuesSecondary.shipPreference &&
    initialValuesSecondary.addressOne &&
    initialValuesSecondary.country &&
    initialValuesSecondary.state &&
    initialValuesSecondary.city &&
    initialValuesSecondary.postalCode
  );

  const handleAddAddress = () => {
    setSecondaryOpen((prev) => !prev);
  };

  return (
    <>
      <DetailLabel
        label={
          <Box>
            Primary Address{' '}
            <Box component='span' sx={styles.required}>
              *
            </Box>
          </Box>
        }
      >
        {showPrimarySummary ? (
          <AddressSummary data={initialValues} edit={toggle} />
        ) : (
          <Typography onClick={toggle} sx={styles.noneField}>
            Enter address
          </Typography>
        )}
      </DetailLabel>

      {showSecondarySummary ? (
        <DetailLabel label={<Box>Secondary Address</Box>}>
          <AddressSummary data={initialValuesSecondary} edit={handleAddAddress} />
        </DetailLabel>
      ) : (
        <Button
          type='button'
          sx={styles.addAddressBtn}
          disabled={!showPrimarySummary}
          onClick={handleAddAddress}
        >
          Add Address
        </Button>
      )}
      <PrimaryAddressModal
        open={isPrimaryOpen}
        toggleModal={toggle}
        initialValues={initialValues}
      />
      <SecondaryAddressModal
        open={isSecondaryOpen}
        toggleModal={handleAddAddress}
        initialValues={initialValuesSecondary}
        enteredFields={showSecondarySummary}
      />
    </>
  );
};
