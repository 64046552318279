import gql from 'graphql-tag';

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      imageProfileLink
      firstName
      lastName
      role
      isRegister
      isConfirmEmail
      isInvited
      isFirstLogin
      secondaryAddress {
        addressOne
        addressTwo
        city
        country {
          id
          name
        }
        countryId
        postalCode
        shipPreference
        state {
          id
          name
          stateCode
        }
        stateId
      }
      tester {
        age
        birthdate
        gender
        phoneNumber
        country {
          id
          name
        }
        state {
          id
          name
          stateCode
        }
        shipPreference
        occupation
        city
        addressOne
        addressTwo
        postalCode
        participationInTests
        isCallOrText
        weightLbs
        weightKgs
        heightMetric
        heightImperialInch
        heightImperialFeet
        shoeSizeAdditionalInfo
        topSizes {
          id
          name
          code
        }
        bottomSizes {
          id
          name
          code
        }
        shoeWidth
        runningShoeSizes {
          id
          name
          code
        }
        hikingShoeSizes {
          id
          name
          code
        }
        generalShoeSizes {
          id
          name
          code
        }
        sandalShoeSizes {
          id
          name
          code
        }
        topSizes {
          id
          name
          code
        }
        bottomSizes {
          id
          name
          code
        }
        chestInches
        chestCm
        waistAtNavelInches
        waistAtNavelCm
        waistAtPantsInches
        waistAtPantsCm
        hipsInches
        hipsCm
        inseamInches
        inseamCm
        vehicleBasedCampingFreq
        vehicleBasedCampingFreqIsWantToTest
        isWorkForAnOutdoorCompany
        isHaveDog
        isRecreateWithKids
        typeGearWouldTest
        campingFreq
        campingFreqIsWantToTest
        hikingFreq
        hikingFreqIsWantToTest
        backpackingFreq
        backpackingFreqIsWantToTest
        roadRunningFreq
        roadRunningFreqIsWantToTest
        trailRunningFreq
        trailRunningFreqIsWantToTest
        walkingFreq
        walkingFreqIsWantToTest
        casualWearFreq
        casualWearFreqIsWantToTest
        rockClimbingFreq
        rockClimbingFreqIsWantToTest
        iceClimbingFreq
        iceClimbingFreqIsWantToTest
        backCountrySkiingFreq
        backCountrySkiingFreqIsWantToTest
        nordicSkiingFreq
        nordicSkiingFreqIsWantToTest
        alpineSkiingFreq
        alpineSkiingFreqIsWantToTest
        mountainBikingFreq
        mountainBikingFreqIsWantToTest
        roadBikingFreq
        roadBikingFreqIsWantToTest
        fishingFreq
        fishingFreqIsWantToTest
        supBoardingFreq
        supBoardingFreqIsWantToTest
        kayakingFreq
        kayakingFreqIsWantToTest
        huntingFreq
        huntingFreqIsWantToTest
        bikepackingFreq
        bikepackingFreqIsWantToTest
        packraftingFreq
        packraftingFreqIsWantToTest
        alpineClimbingFreq
        alpineClimbingFreqIsWantToTest
        mountaineeringFreq
        mountaineeringFreqIsWantToTest
        snowsShoeingFreq
        snowsShoeingFreqIsWantToTest
        relatesOutdoorProductTestingDescription
        didYouHearAboutUs
        testerGroup
        testerStatusInternal
        testerStatusExternal
        isAvailable
        testStatus
        notesInternal
        agreementSigned
        agreementLink
        willTest
        clothingSizeAdditionalInfo
        favoriteActivities
        otherActivities {
          name
          value
          wantToTest
        }
      }
      customer {
        id
        brand {
          name
          id
        }
      }
    }
  }
`;

export const GET_TESTERS = gql`
  query getTesters($options: optionsGettingTesters) {
    getTesters(options: $options) {
      count
      rows {
        id
        email
        firstName
        lastName
        contactName
        tester {
          testerStatusInternal
          testerStatusExternal
          testStatus
          testerGroup
          state {
            id
            name
            stateCode
          }
          city
          notesInternal
          relatesOutdoorProductTestingDescription
          shoeSizeAdditionalInfo
          favoriteActivities
        }
        testCompletedCount
        isInvited
        isRegister
      }
    }
  }
`;

export const GET_AVAILABLE_TESTERS = gql`
  query getTesters($options: optionsGettingTesters) {
    getTesters(options: $options) {
      count
      rows {
        id
        contactName
        tester {
          testerGroup
          state {
            id
            name
          }
          city
          relatesOutdoorProductTestingDescription
          notesInternal
          shoeSizeAdditionalInfo
        }
      }
    }
  }
`;

export const GET_TESTERS_TO_TEST = gql`
  query getTestersToTest($testId: ID!) {
    getTestersToTest(testId: $testId) {
      count
      notStartedCount
      startedCount
      rows {
        id
        user {
          id
          contactName
        }
        testerStatus
        imagesCount
        startedDate
      }
    }
  }
`;

export const GET_TEST_PAGE_INFO = gql`
  query useGetTestPageInfo(
    $testId: ID!
    $limit: Int
    $offset: Int
    $sort: TestTesterSort
    $candidatesSort: CandidateSort
  ) {
    getTestersToTest(testId: $testId, limit: $limit, offset: $offset, sort: $sort) {
      count
      notStartedCount
      startedCount
      rows {
        id
        testerStatus
        lastUpdated
        grade
        imagesCount
        startedDate
        user {
          id
          contactName
        }
      }
    }
    getCandidatesToTest(testId: $testId, limit: $limit, offset: $offset, sort: $candidatesSort) {
      rows {
        id
        lastUpdated
        candidateStatus
        user {
          id
          contactName
          tester {
            testerGroup
            city
            state {
              id
              name
            }
          }
        }
      }
      invitedCount
      acceptedCount
      declinedCount
      rejectedCount
      enrolledCount
    }
    getTestMediaByTestId(testId: $testId) {
      rows {
        id
        caption
        isShareCustomer
        fileUrl
        testTester {
          id
          user {
            id
            contactName
          }
        }
      }
      count
    }
  }
`;

export const GET_TESTER_VALUES = gql`
  query getPossibleValuesTesters {
    getPossibleValuesTesters {
      participationInTests
      testerGroups
      shoeSizeOptions {
        woman {
          id
          name
          code
        }
        womanUS {
          id
          name
          code
        }

        man {
          id
          name
          code
        }

        manUS {
          id
          name
          code
        }
      }
      topSizeOptions {
        woman {
          id
          name
          code
        }
        womanUS {
          id
          name
          code
        }

        man {
          id
          name
          code
        }

        manUS {
          id
          name
          code
        }
      }
      bottomSizeOptions {
        woman {
          id
          name
          code
        }
        womanUS {
          id
          name
          code
        }

        man {
          id
          name
          code
        }

        manUS {
          id
          name
          code
        }
      }
      shoeSizeOptions {
        woman {
          id
          name
          code
        }
        womanUS {
          id
          name
          code
        }

        man {
          id
          name
          code
        }

        manUS {
          id
          name
          code
        }
      }
      testerStatusInternals {
        title
        value
      }
      testerStatusExternals {
        title
        value
      }
      testerTestStatuses {
        title
        value
      }
      activities {
        title
        value
      }
      frequencies
      preferences {
        title
        value
      }
      genders
      frequencyValues {
        title
        value
      }
      heightMetrics
      heightImperialFeets
      heightImperialInches
      shoeWidth
      typeGearWouldTest
    }
  }
`;

export const GET_TESTER_BY_ID = gql`
  query getTesterById($testerId: ID!) {
    getTesterById(testerId: $testerId) {
      id
      email
      firstName
      lastName
      contactName
      isInvited
      isRegister
      imageProfileLink
      tester {
        otherActivities {
          title: name
          value
          wantToTest
        }
        age
        birthdate
        gender
        phoneNumber
        country {
          id
          name
        }
        state {
          id
          name
        }
        city
        addressOne
        addressTwo
        postalCode
        participationInTests
        isCallOrText
        weightLbs
        weightKgs
        heightImperialInch
        heightImperialFeet
        heightMetric
        vehicleBasedCampingFreq
        shoeSizeAdditionalInfo
        chestInches
        chestCm
        waistAtNavelInches
        waistAtNavelCm
        waistAtPantsInches
        waistAtPantsCm
        hipsInches
        hipsCm
        inseamInches
        inseamCm
        shoeWidth
        runningShoeSizes {
          id
          name
          code
        }
        hikingShoeSizes {
          id
          name
          code
        }
        generalShoeSizes {
          id
          name
          code
        }
        sandalShoeSizes {
          id
          name
          code
        }
        topSizes {
          id
          name
          code
        }
        bottomSizes {
          id
          name
          code
        }
        isWorkForAnOutdoorCompany
        isHaveDog
        isRecreateWithKids
        campingFreq
        hikingFreq
        backpackingFreq
        roadRunningFreq
        trailRunningFreq
        walkingFreq
        casualWearFreq
        rockClimbingFreq
        iceClimbingFreq
        backCountrySkiingFreq
        nordicSkiingFreq
        alpineSkiingFreq
        mountainBikingFreq
        roadBikingFreq
        fishingFreq
        supBoardingFreq
        kayakingFreq
        huntingFreq
        bikepackingFreq
        packraftingFreq
        alpineClimbingFreq
        mountaineeringFreq
        snowsShoeingFreq
        snowsShoeingFreqIsWantToTest
        relatesOutdoorProductTestingDescription
        didYouHearAboutUs
        testerGroup
        testerStatusInternal
        testerStatusExternal
        testStatus
        notesInternal
        favoriteActivities
        agreementSigned
        agreementLink
        clothingSizeAdditionalInfo
        typeGearWouldTest
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getCountries {
    getCountries {
      id
      name
      iso2
      iso3
    }
  }
`;

export const GET_STATES = gql`
  query getStates($countryId: ID!, $search: String, $limit: Int, $offset: Int) {
    getStates(countryId: $countryId, search: $search, limit: $limit, offset: $offset) {
      id
      name
      stateCode
    }
  }
`;

export const GET_CITIES = gql`
  query getCities($stateId: ID!, $search: String, $limit: Int, $offset: Int) {
    getCities(stateId: $stateId, search: $search, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;

export const GET_TEST_BY_USER_ID = gql`
  query getTestsByUserId($userId: ID!, $offset: Int, $limit: Int, $sort: MyTestSort) {
    getTestsByUserId(userId: $userId, offset: $offset, limit: $limit, sort: $sort) {
      rows {
        id
        brand {
          name
          id
        }
        status
        shippingDate
        expectedArrivalDate
        testerOverallStatus
        startDate
        endDate
        styleDescription
      }
      count
      competedTestCount
      ongoingTestCount
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers(
    $search: String
    $limit: Int
    $offset: Int
    $sortType: sortCustomer
    $doNotShowUnregistered: Boolean
  ) {
    getCustomers(
      search: $search
      limit: $limit
      offset: $offset
      sortType: $sortType
      doNotShowUnregistered: $doNotShowUnregistered
    ) {
      count
      rows {
        id
        email
        contactName
        isInvited
        isRegister
        customer {
          id
          brand {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query getBrands($search: String, $limit: Int, $offset: Int) {
    getBrands(search: $search, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;

export const GET_CANDIDATES_TO_TEST = gql`
  query getCandidatesToTest($testId: ID!, $limit: Int, $offset: Int, $sort: CandidateSort) {
    getCandidatesToTest(testId: $testId, limit: $limit, offset: $offset, sort: $sort) {
      rows {
        id
        user {
          contactName
          tester {
            testerGroup
            city
            state {
              id
              name
            }
          }
        }
      }
      invitedCount
      acceptedCount
      declinedCount
      rejectedCount
      enrolledCount
    }
  }
`;

export const GET_TESTS = gql`
  query getTests($options: optionsGettingTests) {
    getTests(options: $options) {
      rows {
        id
        brand {
          id
          name
        }
        styleDescription
        testType
        duration
        startDate
        endDate
        lastUpdated
        status
        testers {
          user {
            id
            contactName
          }
        }
      }
      count
    }
  }
`;

export const GET_TEST = gql`
  query getTest($testId: ID!) {
    getTest(testId: $testId) {
      __typename
      id
      brand {
        name
      }
      status
      styleDescription
      testOverview
      productUse
      testType
      duration
      participationReqs
      endDate
      quantity
      colorSizeGender
      itemType
      shippingDate
      expectedArrivalDate
      reports {
        id
        linkName
        link
        visibility
        visibilityTestUsers {
          id
          user {
            contactName
          }
        }
      }
    }
  }
`;

export const GET_POSSIBLE_VALUES_TEST = gql`
  query getPossibleValuesTest {
    getPossibleValuesTest {
      testType
      testTesterGrade
      testStatus {
        title
        value
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: ID) {
    getUserById(userId: $userId) {
      id
      email
      imageProfileLink
      firstName
      lastName
      role
      isRegister
      isConfirmEmail
      isInvited
      customer {
        id
        brand {
          name
          id
        }
      }
    }
  }
`;

export const GET_TESTER_COUNTRIES = gql`
  query getTesterGroupedCountries {
    getTesterGroupedCountries {
      id
      name
    }
  }
`;

export const GET_TESTER_STATES = gql`
  query getTesterGroupedStates {
    getTesterGroupedStates {
      id
      name
    }
  }
`;

export const GET_TESTER_CITIES = gql`
  query getTesterGroupedCities {
    getTesterGroupedCities
  }
`;

export const GET_INVITE_TEST_CANDIDATES = gql`
  query getTextInvitedTestCandidates($testCandidateIds: [ID!]!) {
    getTextInvitedTestCandidates(testCandidateIds: $testCandidateIds) {
      text
      subject
    }
  }
`;

export const GET_TEST_BY_CANDIDATE_TOKEN = gql`
  query getTestByCandidateToken($inviteToken: String!) {
    getTestByCandidateToken(inviteToken: $inviteToken) {
      id
      brand {
        name
        id
      }
      colorSizeGender
      itemType
      styleDescription
      testOverview
      productUse
      testType
      duration
      participationReqs
      testerOverallStatus
      endDate
    }
  }
`;

export const GET_MY_TESTS = gql`
  query getMyTests($limit: Int, $offset: Int, $sort: MyTestSort, $isGetTesterInfo: Boolean!) {
    getMyTests(limit: $limit, offset: $offset, sort: $sort) {
      count
      competedTestCount
      ongoingTestCount @include(if: $isGetTesterInfo)
      rows {
        id
        testerOverallStatus
        brand {
          name
        }
        styleDescription
        startDate
        endDate
        shippingDate
        expectedArrivalDate
        status @include(if: $isGetTesterInfo)
      }
    }
  }
`;

export const GET_MY_TEST = gql`
  query getMyTest($testId: ID!, $isGetCustomerInfo: Boolean!, $isGetTesterInfo: Boolean!) {
    getMyTest(testId: $testId) {
      id
      brand {
        name
        id
      }
      status @include(if: $isGetTesterInfo)
      styleDescription
      testOverview
      productUse
      testType
      duration
      participationReqs
      startDate
      endDate
      quantity
      colorSizeGender
      itemType
      shippingDate
      expectedArrivalDate
      testerOverallStatus
      reports {
        id
        linkName
        link
        visibility
        dateCreate
      }
      media {
        id
        caption
        type
        fileUrl
        testUserId
        testTester @include(if: $isGetCustomerInfo) {
          id
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_ADMINS = gql`
  query getUsersWithRoleAdmin(
    $search: String
    $limit: Int
    $offset: Int
    $sortType: AdminSortType
  ) {
    getUsersWithRoleAdmin(search: $search, limit: $limit, offset: $offset, sortType: $sortType) {
      count
      rows {
        id
        firstName
        email
        isRegister
        isInvited
      }
    }
  }
`;

export const GET_ADMIN_BY_ID = gql`
  query getUsersWithRoleAdminById($id: ID!) {
    getUsersWithRoleAdminById(id: $id) {
      id
      email
      firstName
      imageProfileLink
      lastName
      contactName
      isRegister
      isInvited
    }
  }
`;
