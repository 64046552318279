import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import { Flex } from '../common/CommonStyledComponents';
import { BackButton } from '../UI';
import { style } from './style';

export const InviteToTestHeader = ({ text, inviteAccept, inviteReject, testData }) => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingBottom: '20px',
      }}
    >
      <BackButton title={text} navigateStep={1} />
      {testData === 'INVITED' && (
        <Flex
          sx={{
            '@media(max-width: 576px)': {
              marginBottom: '20px',
            },
            '@media(max-width: 390px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Button
            variant='contained'
            color='primary'
            sx={{
              ...style.button,
              '@media(max-width: 390px)': {
                width: '100%',
              },
            }}
            onClick={inviteAccept}
          >
            {'Sign Me Up!'}
          </Button>
          <Button
            variant='outlined'
            sx={{
              ...style.button,
              marginLeft: '12px',
              '@media(max-width: 390px)': {
                width: '100%',
                margin: '10px 0px 0px 0px',
              },
            }}
            onClick={inviteReject}
          >
            {'Sorry, Can’t This Time'}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

InviteToTestHeader.propTypes = {
  text: PropTypes.string.isRequired,
  inviteAccept: PropTypes.func.isRequired,
  inviteReject: PropTypes.func.isRequired,
};
