export const steps = ['contact', 'details', 'activities', 'about', 'testing', 'notes'];

export const initialValues = {
  fullName: '',
  email: '',
  phoneNumber: '',
  isCallOrText: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  stateId: '',
  postalCode: '',
  countryId: '1',
  birthdate: '',
  gender: '',
  weightLbs: '',
  weightKgs: '',
  heightImperialInch: '',
  heightImperialFeet: '',
  heightMetric: '',
  chestInches: '',
  chestCm: '',
  waistAtNavelInches: '',
  waistAtNavelCm: '',
  waistAtPantsInches: '',
  waistAtPantsCm: '',
  hipsInches: '',
  hipsCm: '',
  inseamInches: '',
  inseamCm: '',
  shoeSizeAdditionalInfo: '',
  clothingSizeAdditionalInfo: '',
  topSizes: [],
  bottomSizes: [],
  participationInTests: '',
  isWorkForAnOutdoorCompany: '',
  isHaveDog: '',
  isRecreateWithKids: '',
  campingFreq: 'NEVER',
  campingFreqIsWantToTest: false,
  hikingFreq: 'NEVER',
  hikingFreqIsWantToTest: false,
  trailRunningFreq: 'NEVER',
  trailRunningFreqIsWantToTest: false,
  backpackingFreq: 'NEVER',
  backpackingFreqIsWantToTest: false,
  roadRunningFreq: 'NEVER',
  roadRunningFreqIsWantToTest: false,
  walkingFreq: 'NEVER',
  walkingFreqIsWantToTest: false,
  casualWearFreq: 'NEVER',
  casualWearFreqIsWantToTest: false,
  rockClimbingFreq: 'NEVER',
  rockClimbingFreqIsWantToTest: false,
  nordicSkiingFreq: 'NEVER',
  nordicSkiingFreqIsWantToTest: false,
  iceClimbingFreq: 'NEVER',
  iceClimbingFreqIsWantToTest: false,
  alpineSkiingFreq: 'NEVER',
  alpineSkiingFreqIsWantToTest: false,
  mountainBikingFreq: 'NEVER',
  mountainBikingFreqIsWantToTest: false,
  roadBikingFreq: 'NEVER',
  roadBikingFreqIsWantToTest: false,
  fishingFreq: 'NEVER',
  fishingFreqIsWantToTest: false,
  supBoardingFreq: 'NEVER',
  supBoardingFreqIsWantToTest: false,
  kayakingFreq: 'NEVER',
  kayakingFreqIsWantToTest: false,
  backCountrySkiingFreq: 'NEVER',
  backCountrySkiingFreqIsWantToTest: false,
  relatesOutdoorProductTestingDescription: '',
  testerGroup: '',
  testerStatusInternal: '',
  testerStatusExternal: '',
  notesInternal: '',
  agreementSigned: null,
  agreementLink: '',
  favoriteActivities: [],
  generalShoeSizes: [],
  runningShoeSizes: [],
  hikingShoeSizes: [],
  sandalShoeSizes: [],
  shoeWidth: [],
  typeGearWouldTest: [],
  occupation: '',
};
