import Typography from '@mui/material/Typography';
import { instanceOf } from 'prop-types';
import { Flex } from 'src/components/common/CommonStyledComponents';
import { DetailFieldReadOnly, DetailLabel } from 'src/components/UI/detail';
import { CalendarIcon } from 'src/components/UI/icons/CalendarIcon';
import { formatDate } from 'src/utils/helpers';

export const TestDetailsTab = ({ data }) => (
  <Flex
    sx={{
      flexDirection: 'column',
    }}
  >
    <DetailFieldReadOnly label='Brand' value={data.brand?.name} />
    <DetailFieldReadOnly label='Style' value={data.styleDescription} />
    <DetailFieldReadOnly label='Item Type' value={data.itemType} />
    <DetailFieldReadOnly label='Test Overview' value={data.testOverview} />
    <DetailFieldReadOnly label='Product Use/Design Intent' value={data.productUse} />
    {/* <DetailFieldReadOnly label='Type' value={data.testType} /> */}
    <DetailFieldReadOnly label='Duration' value={data.duration} />
    <DetailFieldReadOnly label='Participation Requirements' value={data.participationReqs} />
    <DetailLabel label='End Date'>
      <Flex>
        <CalendarIcon />
        <Typography fontSize={14}>{formatDate(data.endDate)}</Typography>
      </Flex>
    </DetailLabel>
  </Flex>
);

TestDetailsTab.propTypes = {
  data: instanceOf(Object),
};
