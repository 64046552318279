import Box from '@mui/material/Box';
import { instanceOf } from 'prop-types';
import { DetailLabel } from 'src/components';

import { DetailDate } from '../UI/detail/DetailDate';
import { DetailText } from '../UI/detail/DetailText';

export const InviteTestInfo = ({ values }) => (
  <Box>
    <DetailLabel label='Brand'>
      <DetailText value={values?.brand?.name} />
    </DetailLabel>
    <DetailLabel label='Style'>
      <DetailText value={values?.styleDescription} />
    </DetailLabel>
    <DetailLabel label='Color/Size/Gender'>
      <DetailText value={values?.colorSizeGender} />
    </DetailLabel>
    <DetailLabel label='Item Type'>
      <DetailText value={values?.itemType} />
    </DetailLabel>
    <DetailLabel label='Test Overview'>
      <DetailText value={values?.testOverview} />
    </DetailLabel>
    <DetailLabel label='Product Use/Design Intent'>
      <DetailText value={values?.productUse} />
    </DetailLabel>
    <DetailLabel label='Duration'>
      <DetailText value={values?.duration} />
    </DetailLabel>
    <DetailLabel label='Participation Requirements'>
      <DetailText value={values?.participationReqs} />
    </DetailLabel>
    <DetailLabel label='End Date'>
      <DetailDate value={values?.endDate} />
    </DetailLabel>
  </Box>
);

InviteTestInfo.propTypes = {
  values: instanceOf(Object),
};
