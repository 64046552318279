import Box from '@mui/material/Box';

import { styles } from './styles';

const AddressSummary = ({ data, edit }) => {
  return (
    <Box sx={styles.addressSummary}>
      <section onClick={edit}>
        <p>{data?.addressOne}</p>
        <p>{data?.addressTwo}</p>
        <p>
          {data?.city}, {data?.state?.stateCode}
        </p>
        <p>{data?.postalCode}</p>
        <p>{data?.country?.name}</p>
        <p>{data?.shipPreference}</p>
      </section>
    </Box>
  );
};

export default AddressSummary;
