import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InviteStatus } from 'src/constants/constants';

import { useAuthCandidate, useSetChoiceInvitedCandidate } from './useMutations';
import { useGetTestByCandidateToken } from './useQueries';
import { useToasts } from './useToasts';

export const useInviteToTest = () => {
  const { search } = useLocation();
  const { ErrorMessage } = useToasts();
  const navigate = useNavigate();

  const { t: token, s: status } = useMemo(() => queryString.parse(search) || {}, [search]);

  const {
    handleSetChoice,
    loading,
    error: errorSetChoiceInvitedCandidate,
  } = useSetChoiceInvitedCandidate();

  const [getTest, { loading: loadingTest, data, error }] = useGetTestByCandidateToken();
  const [authCandidate] = useAuthCandidate();

  const handleSubmit = async (_status) => {
    handleSetChoice(data?.getTestByCandidateToken?.id, _status).catch(() => {
      ErrorMessage('Invitation is no longer valid');
      navigate(`/tests`);
    });
  };

  const handleSubmitAccept = async () => {
    handleSetChoice(data?.getTestByCandidateToken?.id, InviteStatus.ACCEPTED);
  };
  const handleSubmitReject = async () => {
    handleSetChoice(data?.getTestByCandidateToken?.id, InviteStatus.DECLINED);
  };

  useEffect(() => {
    if (token && status !== InviteStatus.LEARN_MORE) {
      handleSubmit(status);
    } else {
      authCandidate({
        variables: {
          inviteToken: token,
        },
      })
        .then(() => {
          getTest({
            variables: {
              inviteToken: token,
            },
          });
        })
        .catch(() => {
          navigate(`/tests`);
        });
    }
  }, []);

  const value = {
    data,
    loading,
    loadingTest,
    error,
    errorSetChoiceInvitedCandidate,
    handleSubmitAccept,
    handleSubmitReject,
  };

  return value;
};
