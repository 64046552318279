import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components';
import { DetailDatePicker } from 'src/components/UI/detail/DetailDatePicker';

export const TestInfo = ({ values, handleChange, testTypeOptions = [] }) => (
  <Box>
    <DetailLabel label='Test Overview'>
      <DetailInput
        name='testOverview'
        handleChange={handleChange}
        value={values.testOverview}
        multiline
      />
    </DetailLabel>
    <DetailLabel label='Product Use/Design Intent'>
      <DetailInput
        name='productUse'
        handleChange={handleChange}
        value={values.productUse}
        multiline
      />
    </DetailLabel>
    <DetailLabel label='Type'>
      <DetailSelect
        label='Type'
        name='testType'
        handleChange={handleChange}
        value={values.testType}
        possibleValues={testTypeOptions}
      />
    </DetailLabel>
    <DetailLabel label='Duration'>
      <DetailInput name='duration' handleChange={handleChange} value={values.duration} />
    </DetailLabel>
    <DetailLabel label='Participation Requirements'>
      <DetailInput
        name='participationReqs'
        handleChange={handleChange}
        value={values.participationReqs}
        multiline
        textStyle={{
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': '3',
        }}
      />
    </DetailLabel>
    <DetailLabel label='End Date'>
      <DetailDatePicker name='endDate' handleChange={handleChange} value={values.endDate} />
    </DetailLabel>
  </Box>
);

TestInfo.propTypes = {
  values: instanceOf(Object),
  handleChange: func,
  testTypeOptions: instanceOf(Array),
};
