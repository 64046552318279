import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { InfoIcon } from 'src/assets/icons';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';

import { detailsTabStyles } from './backDropStyles';
import { ChestModal } from './modal/ChestModal';

export const DetailsChestSize = ({ handleChangeInchesToCm, handleChangeCm, testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, toggleModal] = useState(false);
  const { values = {}, errors = {}, handleSubmit } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
    setIsEditMode(false);
  };

  return (
    <>
      <Box>
        {isEditMode ? (
          <Box sx={styles.twoColumns}>
            <Backdrop
              open={isEditMode}
              invisible={false}
              onClick={closeAndSubmit}
              sx={detailsTabStyles.backDropStyle}
            />
            <StyledLabel label='Chest Size (in)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='chestInches'
                onInput={handleChangeInchesToCm('chestCm')}
                value={values.chestInches}
                error={!!errors.chestInches}
                helperText={errors.chestInches}
                placeholder='Inches'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
            <StyledLabel label='Chest Size (cm)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='chestCm'
                onInput={handleChangeCm('chestInches')}
                value={values.chestCm}
                error={!!errors.chestCm}
                helperText={errors.chestCm}
                placeholder='cms'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
          </Box>
        ) : (
          <Box onClick={changeViewBox}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <StyledLabel label='Chest Size' width='unset' sx={detailsTabStyles.styledLabelFont} />
              {testerProfile && (
                <Box
                  sx={{
                    '& svg': {
                      minWidth: '15px',
                      minHeight: '15px',
                      margin: '2px 0px 0px 6.5px',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleToggleModal}
                >
                  <InfoIcon />
                </Box>
              )}
            </Box>
            <Box sx={detailsTabStyles.styleForResultDetail}>
              {values.chestInches !== null || values.chestCm !== null ? (
                `${values.chestInches || '0'}''
                (${values.chestCm || '0'}cm)`
              ) : (
                <Box color='#444'>Enter chest size</Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <ChestModal open={open} toggleModal={handleToggleModal} />
    </>
  );
};

DetailsChestSize.propTypes = {
  handleChangeInchesToCm: func,
  handleChangeCm: func,
};
