import Box from '@mui/material/Box';
import HipsImg from 'src/assets/images/hips-img.png';

import { GeneralModal } from './GeneralModal';

const description = [
  'Use a flexible measuring tape: Fabric or vinyl is best. Metal tapes are too rigid. If you don’t have a flexible measuring tape, use string and after measuring, lay it flat and use a metal tape to measure it.',
  'Stand naturally: Stand with your feet together, upright with your arms relaxed at your sides.',
  'Position the tape: Wrap the tape around the widest part of your hips. This is typically around 7–9 inches (17–23 cm) below your natural waistline.',
  'Ensure the tape is level: It should be horizontal and lie flat against the body without sagging in the back.',
  'Take the measurement: Make sure the tape is snug but not tight, allowing enough room for comfort and movement. You should breathe normally and avoid holding your breath to ensure an accurate measurement.',
];

export const HipsModal = ({ open, toggleModal }) => {
  return (
    <Box>
      <GeneralModal
        open={open}
        toggleModal={toggleModal}
        title='How to Measure Your Hips'
        src={HipsImg}
        description={description}
        helperText='Tip: It’s often more accurate to have another person take your measurements.'
      />
    </Box>
  );
};
