import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { InfoIcon } from 'src/assets/icons';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { styles } from 'src/components/testers/createTesterForm/styles';
import { testerCreationForm } from 'src/styles';

import { detailsTabStyles } from './backDropStyles';
import { WaistAtPantsModal } from './modal/WaistAtPantsModal';

export const DetailsWaistAtPants = ({ handleChangeInchesToCm, handleChangeCm, testerProfile }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, toggleModal] = useState(false);

  const { values = {}, errors = {}, handleSubmit } = useFormikContext();

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit();
    }
  };

  const closeAndSubmit = () => {
    setIsEditMode((prev) => !prev);
    handleSubmit();
  };

  const changeViewBox = () => {
    setIsEditMode((prev) => !prev);
  };

  const handleToggleModal = () => {
    toggleModal((prev) => !prev);
    setIsEditMode(false);
  };

  return (
    <>
      <Box>
        {isEditMode ? (
          <Box sx={styles.twoColumns}>
            <Backdrop
              open={isEditMode}
              invisible={false}
              onClick={closeAndSubmit}
              sx={detailsTabStyles.backDropStyle}
            />
            <StyledLabel label='Waist at Pants (in)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='waistAtPantsInches'
                onInput={handleChangeInchesToCm('waistAtPantsCm')}
                value={values.waistAtPantsInches}
                error={!!errors.waistAtPantsInches}
                helperText={errors.waistAtPantsInches}
                placeholder='Inches'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
            <StyledLabel label='Waist at Pants (cm)' sx={detailsTabStyles.styledLabelFont}>
              <TextField
                type='number'
                sx={testerCreationForm.input}
                size='small'
                name='waistAtPantsCm'
                onInput={handleChangeCm('waistAtPantsInches')}
                value={values.waistAtPantsCm}
                error={!!errors.waistAtPantsCm}
                helperText={errors.waistAtPantsCm}
                placeholder='cms'
                onKeyDown={keyDownHandler}
              />
            </StyledLabel>
          </Box>
        ) : (
          <Box onClick={changeViewBox}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <StyledLabel
                label='Waist at Pants'
                width='unset'
                sx={detailsTabStyles.styledLabelFont}
              />
              {testerProfile && (
                <Box
                  sx={{
                    '& svg': {
                      minWidth: '15px',
                      minHeight: '15px',
                      margin: '2px 0px 0px 6.5px',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleToggleModal}
                >
                  <InfoIcon />
                </Box>
              )}
            </Box>
            <Box sx={detailsTabStyles.styleForResultDetail}>
              {values.waistAtPantsInches !== null || values.waistAtPantsCm !== null ? (
                `${values.waistAtPantsInches || '0'}''
              (${values.waistAtPantsCm || '0'}cm)`
              ) : (
                <Box color='#444'>Enter waist at pants size</Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <WaistAtPantsModal open={open} toggleModal={handleToggleModal} />
    </>
  );
};

DetailsWaistAtPants.propTypes = {
  handleChangeInchesToCm: func,
  handleChangeCm: func,
};
