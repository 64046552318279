import { theme } from 'src/theme';

export const styles = {
  description: {
    '& .MuiTypography-root, &.MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  emptyAddress: {
    width: '100px',
    height: '0px',
  },

  noneField: {
    fontSize: '14px',
    color: '#444444',
    cursor: 'pointer',
  },
  required: {
    color: theme.colors.error,
    marginRight: 2,
  },
  addressSummary: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    '& p': {
      margin: '0px 0px 3px 0px',
    },
  },
  addAddressBtn: {
    '&.MuiButtonBase-root.MuiButton-root': {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      background: 'transparent',
      border: 'none',
      color: '#9B51E0',
      padding: '0px',
      marginBottom: '16px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: '#DEDEDE',
        fontWeight: 400,
      },
    },
  },
};
