import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { BackToTopIcon } from 'src/assets/icons';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Функція для обробки скролу
  const handleScroll = () => {
    if (window.scrollY > 800) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Прокрутка до верху сторінки
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    isVisible && (
      <IconButton
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '40px',
          right: '40px',
          padding: '0px',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          width: '44px',
          height: '44px',
        }}
      >
        <BackToTopIcon />
      </IconButton>
    )
  );
};

export default ScrollToTopButton;
