import Box from '@mui/material/Box';
import { useInviteToTest } from 'src/hooks/useInviteToTest';

import { RequestHandler } from '../requestHandler/requestHandler';
import { InviteToTestHeader } from './inviteToTestHeader';
import { style } from './style';
import { InviteTestInfo } from './TestInfo';

export const InviteToTestContainer = () => {
  const {
    loading,
    loadingTest,
    handleSubmitAccept,
    handleSubmitReject,
    data,
    error,
    errorSetChoiceInvitedCandidate,
  } = useInviteToTest();

  const title = `${data?.getTestByCandidateToken?.brand?.name} - ${data?.getTestByCandidateToken?.styleDescription}`;

  return (
    <Box sx={style.container}>
      <RequestHandler
        loading={loading || loadingTest || !data}
        errorMessage={error?.message || errorSetChoiceInvitedCandidate?.message}
      >
        <InviteToTestHeader
          text={title}
          inviteAccept={handleSubmitAccept}
          inviteReject={handleSubmitReject}
          testData={data?.getTestByCandidateToken?.testerOverallStatus}
        />
        <InviteTestInfo values={data?.getTestByCandidateToken} />
      </RequestHandler>
    </Box>
  );
};
