export const BackToTopIcon = () => {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_8931_5516)'>
        <rect x='4' y='2' width='44' height='44' rx='22' fill='#4F5A6F' />
        <path
          d='M16.5823 33.7223L26.1649 24.1397L35.7475 33.7223L38.7049 30.765L26.1649 18.225L13.6249 30.765L16.5823 33.7223Z'
          fill='white'
        />
        <path d='M12.8 14.925H39.2V18.225H12.8V14.925Z' fill='white' />
      </g>
      <defs>
        <filter
          id='filter0_d_8931_5516'
          x='0'
          y='0'
          width='52'
          height='52'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8931_5516' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_8931_5516'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
