import Box from '@mui/material/Box';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components';
import { YesOrNotText } from 'src/constants/constants';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { AddressFieldsWrapper } from './components/AddressFieldsWrapper';
import { styles } from './styles';

export const Contact = ({ handleChange, values = {}, setValues }) => {
  const handleBoolChange = ({ target }) => {
    const { name, value } = target;

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };
  return (
    <Box>
      <Box sx={styles.requiredWrapper}>
        <span>*</span> Required
      </Box>
      <DetailLabel
        label={
          <Box sx={styles.required}>
            Email <span>*</span>
          </Box>
        }
      >
        <DetailInput
          name='email'
          handleChange={handleChange}
          value={values.email}
          placeholder='Enter Email'
          textTransform='lowercase'
          submitOnValueChange
        />
      </DetailLabel>
      <DetailLabel
        label={
          <Box sx={styles.required}>
            Phone <span>*</span>
          </Box>
        }
      >
        <DetailInput
          type='text'
          name='phoneNumber'
          handleChange={handleChange}
          value={values.phoneNumber}
          placeholder='Enter phone number'
          submitOnValueChange
        />
      </DetailLabel>
      <DetailLabel label='Ok to call or text?'>
        <DetailSelect
          label='Ok to call or text?'
          name='isCallOrText'
          handleChange={handleBoolChange}
          value={booleanToString(values.isCallOrText)}
          possibleValues={YesOrNotText}
          submitOnValueChange
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <AddressFieldsWrapper values={values} setValues={setValues} placeholder='Enter address' />
    </Box>
  );
};

Contact.propTypes = {
  handleChange: func,
  setValues: func,
  values: instanceOf(Object),
  setFieldError: func,
};
