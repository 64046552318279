import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from '@mui/system/styled';
import { bool, node, string } from 'prop-types';
import { useMemo, useState } from 'react';
import { StyledPageContainer } from 'src/components/common/CommonStyledComponents';
import { InviteToTestContainerFromSite } from 'src/components/inviteToTest/inviteToTestContainerFromSite';
import {
  CustomerMediaSection,
  ReportsTab,
  TestDetailsTab,
  TesterMediaSection,
} from 'src/components/myTest/tabs';
import { BackButton, Loader } from 'src/components/UI';
import { UserRoles } from 'src/constants/constants';
import { useAuthContext, useGetMyTestById } from 'src/hooks';
import { theme } from 'src/theme';

import { PageNotMatch } from './PageNotMatch';

const StyledTab = styled(Tab)({
  textTransform: 'capitalize',
  minWidth: 0,
  minHeight: 0,
  px: 4,
  py: 2,
  marginRight: 8,
  borderRadius: 4,
  backgroundColor: theme.colors.neutral.light.superExtra,
  '&.Mui-selected': {
    backgroundColor: theme.colors.tertiary,
    color: theme.colors.neutral.white,
  },
});

const TabPanel = ({ children, name, active }) => (
  <Box role='tabpanel' hidden={!active} id={`tabpanel-${name}`} aria-labelledby={`tab-${name}`}>
    {children}
  </Box>
);

TabPanel.propTypes = {
  children: node,
  name: string,
  active: bool,
};

export const MyTestDetail = () => {
  const { test, loading, refetch } = useGetMyTestById();
  const { user } = useAuthContext();
  const [currentTab, setCurrentTab] = useState('details');

  const tabs = useMemo(() => {
    const { TESTER, CUSTOMER } = UserRoles;
    const lib = [
      {
        name: 'details',
        children: <TestDetailsTab data={test} refetch={refetch} />,
      },
      {
        name: 'uploads',
        children: <TesterMediaSection mediaList={test?.media} />,
        roles: [TESTER],
      },
      {
        name: 'images',
        children: <CustomerMediaSection mediaList={test?.media} />,
        roles: [CUSTOMER],
      },
      {
        name: 'reports',
        children: <ReportsTab reports={test?.reports} />,
      },
    ];

    return lib.filter(({ roles }) => (roles ? roles.includes(user.role) : true));
  }, [test, user.role]);

  const handleChange = (event, value) => {
    event.preventDefault();
    setCurrentTab(value);
  };

  if (loading) return <Loader loading={loading} />;
  if (!test) return <PageNotMatch />;

  return (
    <>
      {test?.testerOverallStatus === 'INVITED' || test?.testerOverallStatus === 'PENDING' ? (
        <StyledPageContainer>
          <InviteToTestContainerFromSite />
        </StyledPageContainer>
      ) : (
        <StyledPageContainer
          sx={{
            maxWidth: 824,
            py: 8,
          }}
        >
          <BackButton title={`${test.brand?.name} - ${test.styleDescription}`} navigateStep={1} />
          <Box mt={10}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label='testerTabs'
              indicatorColor='none'
            >
              {tabs.map((tab) => (
                <StyledTab
                  label={tab.name}
                  key={tab.name}
                  value={tab.name}
                  aria-controls={`testTabPanel-${tab.name}`}
                />
              ))}
            </Tabs>
            <Divider />
            <Box mt={4}>
              {tabs.map(({ name, children }) => (
                <TabPanel key={name} name={name} active={currentTab === name}>
                  {children}
                </TabPanel>
              ))}
            </Box>
          </Box>
        </StyledPageContainer>
      )}
    </>
  );
};
