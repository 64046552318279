import { Form, Formik } from 'formik';
import { useState } from 'react';
import { ConfirmModal } from 'src/components/testOverview/confirmModal/confirmModal';
import { useToasts, useUpdateProfile } from 'src/hooks';

import ADDRESS_SCHEMA from './address.schema';
import { AddressGeneralModal } from './AddressGeneralModal';
import SecondaryAddressModalContent from './SecondaryAddressModalContent';
import { styles } from './styles';

export const SecondaryAddressModal = ({ open, toggleModal, initialValues, enteredFields }) => {
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);

  const [updateProfile] = useUpdateProfile({
    successMessage: 'Secondary address added',
  });

  const [removeAddressFromProfile] = useUpdateProfile({
    successMessage: 'Secondary address removed',
  });

  const { ErrorMessage } = useToasts();

  const onSubmit = (data) => {
    updateProfile({
      variables: {
        input: {
          secondaryAddress: {
            shipPreference: data.shipPreference,
            postalCode: data.postalCode,
            addressOne: data.addressOne,
            addressTwo: data.addressTwo,
            countryId: data.country.id,
            city: data.city,
            stateId: data.state.id,
          },
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  const handleRemoveToggle = () => {
    setRemoveModalIsOpen((prev) => !prev);
  };

  const handleRemoveAddress = () => {
    removeAddressFromProfile({
      variables: {
        input: {
          secondaryAddress: null,
        },
      },
      onCompleted: () => {
        toggleModal();
      },
      onError: () => {
        ErrorMessage('Error. Please, try again.');
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ADDRESS_SCHEMA}
        validateOnMount={true}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form>
              <AddressGeneralModal
                title='Secondary Address'
                secondary
                open={open}
                toggleModal={toggleModal}
                handleClickSubmit={handleSubmit}
                disabledSave={!!Object.keys(errors).length}
                enteredFields={enteredFields}
                handleRemove={handleRemoveToggle}
              >
                <SecondaryAddressModalContent />
              </AddressGeneralModal>
            </Form>
          );
        }}
      </Formik>
      <ConfirmModal
        sx={styles.confirmModal}
        open={removeModalIsOpen}
        toggleModal={handleRemoveToggle}
        callback={handleRemoveAddress}
        labalTest='Are you sure you want to remove this address??'
        agreeButtonTex='Yes, Remove it'
      />
    </>
  );
};
