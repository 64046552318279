export const InfoIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM7.25 5.5C7.25 5.08579 7.58579 4.75 8 4.75H8.00625C8.42046 4.75 8.75625 5.08579 8.75625 5.5C8.75625 5.91421 8.42046 6.25 8.00625 6.25H8C7.58579 6.25 7.25 5.91421 7.25 5.5ZM8 7.25C8.41421 7.25 8.75 7.58579 8.75 8V10.5C8.75 10.9142 8.41421 11.25 8 11.25C7.58579 11.25 7.25 10.9142 7.25 10.5V8C7.25 7.58579 7.58579 7.25 8 7.25Z'
        fill='#676767'
      />
    </svg>
  );
};
