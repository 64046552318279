import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

export const GeneralModal = ({ open, toggleModal, title, src, description, helperText }) => {
  return (
    <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '20px',
          borderRadius: '4px',
          minWidth: 300,
          maxWidth: '820px',
          width: '100%',
        }}
      >
        <Box
          id='modal-title'
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20px',
            paddingBottom: '19px',
            borderBottom: '1px solid #DEDEDE',
            marginBottom: '20px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Box>
        <Box
          id='modal-description'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            margin: '0px 20px',
            alignItems: 'center',
            '& img': {
              maxHeight: '413px',
            },
          }}
        >
          <img src={src} alt='Preview img'></img>
          <Box
            sx={{
              maxWidth: '390px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              '& ol': {
                paddingLeft: '15px',
              },
            }}
          >
            <ol>
              {description.map((item) => {
                return <li>{item}</li>;
              })}
            </ol>
            <Box>{helperText}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={toggleModal}
            sx={{
              width: '50px',
              height: '28px',
              background: '#000000',
              color: '#ffffff',
              borderRadius: '4px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
            }}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
