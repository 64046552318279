import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { StyledLabel, StyledSelect } from 'src/components/common/CommonStyledComponents';
import { DatePicker } from 'src/components/common/DatePicker';
import { BrandAutocomplete } from 'src/components/customers/BrandAutocomplete';
import { useGetPossibleValuesTest } from 'src/hooks';

import { styles } from './styles';

export const TestPropertiesSection = ({
  errors = {},
  handleChangeValue,
  values = {},
  setFieldValue,
  touched,
  handleBlur,
  setFieldError,
}) => {
  const possibleValues = useGetPossibleValuesTest();

  return (
    <Box sx={styles.createTestContainer}>
      <StyledLabel label='Brand' required className='mb-20'>
        <Box sx={styles.smallField}>
          <BrandAutocomplete
            name='brand'
            value={values.brand}
            setFieldValue={setFieldValue}
            error={touched.brand && !!errors.brand}
            onBlur={handleBlur}
            helperText={touched.brand && errors.brand}
          />
        </Box>
      </StyledLabel>
      <Box sx={styles.middleField}>
        <StyledLabel label='Style' required className='mb-20'>
          <TextField
            size='small'
            name='style'
            onInput={handleChangeValue}
            required
            value={values.style}
            error={touched.style && !!errors.style}
            onBlur={handleBlur}
            helperText={touched.style && errors.style}
            placeholder='Enter Style Description'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.middleField}>
        <StyledLabel label='Test Overview' className='mb-20'>
          <TextField
            minRows={4}
            size='small'
            name='testOverview'
            multiline
            onInput={handleChangeValue}
            value={values.testOverview}
            error={touched.testOverview && !!errors.testOverview}
            onBlur={handleBlur}
            helperText={errors.testOverview}
            placeholder='Describe the test including objectives'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.middleField}>
        <StyledLabel label='Product Use/Design Intent' className='mb-20'>
          <TextField
            minRows={4}
            size='small'
            name='productUse'
            multiline
            onInput={handleChangeValue}
            value={values.productUse}
            error={touched.productUse && !!errors.productUse}
            onBlur={handleBlur}
            helperText={errors.productUse}
            placeholder='Enter Product Use or Design Intent'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.smallField}>
        <StyledLabel label='Test Type' className='mb-20'>
          <StyledSelect
            name='testType'
            onChange={handleChangeValue}
            error={touched.testType && !!errors.testType}
            onBlur={handleBlur}
            value={values.testType}
            helperText={errors.testType}
            placeholder='Select Type'
          >
            {possibleValues.testType?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </StyledSelect>
        </StyledLabel>
      </Box>
      <Box sx={styles.smallField}>
        <StyledLabel label='Duration' className='mb-20'>
          <TextField
            size='small'
            name='duration'
            onInput={handleChangeValue}
            value={values.duration}
            error={touched.duration && !!errors.duration}
            onBlur={handleBlur}
            helperText={errors.duration}
            placeholder='Enter Duration'
          />
        </StyledLabel>
      </Box>
      <StyledLabel label='Participation Requirements' className='mb-20'>
        <TextField
          minRows={4}
          size='small'
          name='participationReqs'
          multiline
          onInput={handleChangeValue}
          value={values.participationReqs}
          error={touched.participationReqs && !!errors.participationReqs}
          onBlur={handleBlur}
          helperText={errors.participationReqs}
          placeholder='Enter Participation Requirements'
        />
      </StyledLabel>
      <Box sx={styles.smallField}>
        <StyledLabel label='End Date' className='mb-20'>
          <DatePicker
            value={values.endDate}
            name='endDate'
            hideLabel
            touched={touched.endDate}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            placeholder='Set End Date'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.smallField}>
        <StyledLabel label='Quantity' className='mb-20'>
          <TextField
            size='small'
            name='quantity'
            onInput={handleChangeValue}
            value={values.quantity}
            error={touched.quantity && !!errors.quantity}
            onBlur={handleBlur}
            helperText={errors.quantity}
            placeholder='Enter Quantity'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.smallField}>
        <StyledLabel label='Color/Size/Gender' className='mb-20'>
          <TextField
            minRows={4}
            size='small'
            name='colorSizeGender'
            multiline
            onInput={handleChangeValue}
            value={values.colorSizeGender}
            error={touched.colorSizeGender && !!errors.colorSizeGender}
            onBlur={handleBlur}
            helperText={errors.colorSizeGender}
            placeholder='Enter Info'
          />
        </StyledLabel>
      </Box>
      <Box sx={styles.smallField}>
        <StyledLabel label='ItemType' className='mb-20'>
          <TextField
            size='small'
            name='itemType'
            onInput={handleChangeValue}
            value={values.itemType}
            error={touched.itemType && !!errors.itemType}
            onBlur={handleBlur}
            helperText={errors.itemType}
            placeholder='e.g. Boots, gloves, etc'
          />
        </StyledLabel>
      </Box>
    </Box>
  );
};

TestPropertiesSection.propTypes = {
  handleChangeValue: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  touched: PropTypes.objectOf(PropTypes.bool),
  handleBlur: PropTypes.func,
};
