import { useEffect, useMemo, useState } from 'react';

import { useGetCities, useGetCountries, useGetStates } from './useQueries';

export const useGeo = () => {
  const { data: countryData } = useGetCountries();
  const [getStates, { data: stateData }] = useGetStates();
  const [getCities, { data: cityData }] = useGetCities();

  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);

  useEffect(
    () =>
      getStates({
        variables: {
          countryId: countryId || '',
        },
      }),
    [countryId],
  );

  useEffect(
    () =>
      getCities({
        variables: {
          stateId: stateId || '',
        },
      }),
    [stateId],
  );

  const geoData = useMemo(() => {
    return {
      countries: countryData?.getCountries || [],
      states: stateData?.getStates || [],
      cities: cityData?.getCities || [],
      setCountryId,
      setStateId,
    };
  }, [cityData?.getCities, countryData?.getCountries, stateData?.getStates]);

  return geoData;
};
