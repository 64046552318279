import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyTableBanner } from 'src/components/UI';
import { formatDate } from 'src/utils/helpers';

import { Status, StyledTable, StyledTableRow, TableHeader } from '../../UI/tables';

const COLUMNS = [
  {
    title: 'Brand',
    name: 'brand',
    sortKey: 'brand',
  },
  {
    title: 'Style',
    name: 'styleDescription',
    sortKey: 'styleDescription',
  },
  {
    title: 'Start Date',
    name: 'startDate',
    sortKey: 'startDate',
  },
  {
    title: 'End Date',
    name: 'endDate',
    sortKey: 'endDate',
  },
  {
    title: 'Status',
    name: 'status',
    sortKey: 'status',
  },
];

export const CustomerTestsTable = ({ data = [], refetch }) => {
  const [orderBy, setOrderBy] = useState('startDate');
  const [order, setOrder] = useState('DESC');
  const navigate = useNavigate();

  useEffect(() => {
    refetch({
      sort: {
        type: order,
        field: orderBy,
      },
    });
  }, [order, orderBy]);

  const handleRequestSort = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === 'ASC';
      setOrder(isAsc ? 'DESC' : 'ASC');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleItemClick = useCallback(
    (id) => (evt) => {
      const { tagName } = evt.target;

      if (tagName === 'TR' || tagName === 'TD') {
        navigate(`/tests/${id}`);
      }
    },
    [],
  );

  if (!data?.length) return <EmptyTableBanner text='You don’t have any tests available yet' />;

  return (
    <StyledTable>
      <TableHeader
        columns={COLUMNS}
        orderBy={orderBy}
        order={order}
        createSortHandler={handleRequestSort}
        refetch={refetch}
      />
      <TableBody>
        {data.map((item) => {
          return (
            <StyledTableRow
              key={item.id}
              onClick={handleItemClick(item.id)}
              sx={{
                opacity: item.status.toLowerCase() === 'closed' ? 0.7 : 1,
              }}
            >
              <TableCell
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {item.brand.name}
              </TableCell>
              <TableCell
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {item.styleDescription}
              </TableCell>
              <TableCell>{formatDate(item.startDate)}</TableCell>
              <TableCell>{formatDate(item.endDate)}</TableCell>
              <TableCell>
                <Status status={item.status} />
              </TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

CustomerTestsTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  refetch: PropTypes.func,
};
