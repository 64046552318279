import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { StyledLabel } from 'src/components/common/CommonStyledComponents';
import { AddressSelect } from 'src/components/UI';
import { usePrevious } from 'src/hooks';
import { useGeo } from 'src/hooks/useGeo';
import { testerCreationForm } from 'src/styles';

import { SelectYesOrNot } from '../StyledComponents';

export const Contact = () => {
  const {
    values = {},
    errors = {},
    touched = {},
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormikContext();

  const previousValues = usePrevious({
    country: values.country,
    state: values.state,
  });

  const { countries, states, setCountryId } = useGeo();

  useEffect(() => {
    setCountryId(values.country?.id);
  }, [values.country]);

  useEffect(() => {
    if (!previousValues) return false;
    if (previousValues.country?.id === values.country?.id) return false;
    return setFieldValue('state', '');
  }, [values.country]);

  return (
    <Box
      sx={{
        width: '75%',
      }}
    >
      <StyledLabel label='Name' required>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='fullName'
          onInput={handleChange}
          onBlur={handleBlur}
          required
          value={values.fullName}
          error={touched.fullName && !!errors.fullName}
          helperText={touched.fullName && errors.fullName}
          placeholder='Enter Full Name'
        />
      </StyledLabel>
      <StyledLabel label='Email' required>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='email'
          onInput={handleChange}
          required
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          placeholder='Enter Email'
        />
      </StyledLabel>
      <StyledLabel label='Phone Number'>
        <TextField
          type='text'
          sx={testerCreationForm.input}
          size='small'
          name='phoneNumber'
          onInput={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          placeholder='Enter Phone Number'
        />
      </StyledLabel>
      <StyledLabel label='Ok to Phone or Text'>
        <SelectYesOrNot
          name='isCallOrText'
          onChange={handleChange}
          values={values}
          errors={errors}
          placeholder='Yes/No'
        />
      </StyledLabel>
      <StyledLabel label='Address 1'>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='addressOne'
          onInput={handleChange}
          onBlur={handleBlur}
          value={values.addressOne}
          error={touched.addressOne && !!errors.addressOne}
          helperText={touched.addressOne && errors.addressOne}
          placeholder='Address 1'
        />
      </StyledLabel>
      <StyledLabel label='Address 2'>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='addressTwo'
          onInput={handleChange}
          value={values.addressTwo}
          error={!!errors.addressTwo}
          helperText={errors.addressTwo}
          placeholder='Apt or Unit Number'
        />
      </StyledLabel>
      <StyledLabel label='City'>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='city'
          onInput={handleChange}
          onBlur={handleBlur}
          value={values.city}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          placeholder='Enter City'
        />
      </StyledLabel>
      <StyledLabel label='Country' width='180px'>
        <AddressSelect
          sx={testerCreationForm.addressSelect}
          label='Country'
          value={values.country}
          name='country'
          possibleValues={countries}
          handleChange={setFieldValue}
          placeholder='Select Country'
          handleBlur={handleBlur}
          error={touched.country && !!errors.country}
          helperText={touched.country && errors.country}
        />
      </StyledLabel>
      <StyledLabel label='State/Province' width='180px'>
        <AddressSelect
          sx={testerCreationForm.addressSelect}
          label='State'
          value={values.state}
          name='state'
          possibleValues={states}
          handleChange={setFieldValue}
          placeholder='Select State'
          handleBlur={handleBlur}
          error={touched.state && !!errors.state}
          helperText={touched.state && errors.state}
        />
      </StyledLabel>
      <StyledLabel label='Postal Code' width='180px'>
        <TextField
          sx={testerCreationForm.input}
          size='small'
          name='postalCode'
          onInput={handleChange}
          onBlur={handleBlur}
          value={values.postalCode}
          error={touched.postalCode && !!errors.postalCode}
          helperText={touched.postalCode && errors.postalCode}
          placeholder='Enter Postal Code'
        />
      </StyledLabel>
    </Box>
  );
};
