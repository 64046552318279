import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  FooterContainer,
  HeaderTitleContainer,
  ScrollContainer,
  StyledModal,
  StyledPaper,
} from 'src/components/common/CommonStyledComponents';

import { styles } from './styles';

const Header = ({ title, secondary = false }) => (
  <Box
    sx={{
      padding: '20px 24px',
    }}
  >
    <HeaderTitleContainer>
      <Typography variant='modalTitle' id='modal-title'>
        {title}
        {secondary && (
          <span
            style={{
              color: '#DE4141',
              marginRight: 2,
            }}
          >
            *
          </span>
        )}
      </Typography>
    </HeaderTitleContainer>
  </Box>
);

const Footer = ({
  handleRemove,
  handleClickCancel,
  handleClickSubmit,
  disabled,
  secondary,
  enteredFields,
}) => (
  <FooterContainer>
    <Typography
      sx={{
        fontWeight: 700,
      }}
    >
      <span
        style={{
          color: '#DE4141',
          marginRight: 2,
        }}
      >
        *
      </span>
      Required
    </Typography>
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {secondary && enteredFields && (
        <Button sx={styles.styledRemoveButton} onClick={handleRemove}>
          Remove Address
        </Button>
      )}
      <Button sx={styles.styledCancelButton} onClick={handleClickCancel}>
        Cancel
      </Button>
      <Button sx={styles.styledSubmitButton} onClick={handleClickSubmit} disabled={disabled}>
        Save
      </Button>
    </Box>
  </FooterContainer>
);

export const AddressGeneralModal = ({
  open,
  toggleModal,
  title,
  secondary,
  enteredFields,
  handleRemove,
  handleClickSubmit,
  disabledSave,
  children,
}) => {
  return (
    <StyledModal open={open} onClose={toggleModal} aria-labelledby='modal-title'>
      <StyledPaper>
        <Header title={title} secondary={secondary} />
        <Divider
          sx={{
            margin: '0 24px 32px 24px',
          }}
        />
        <ScrollContainer>{children}</ScrollContainer>
        <Footer
          handleClickSubmit={handleClickSubmit}
          handleClickCancel={toggleModal}
          handleRemove={handleRemove}
          disabled={disabledSave}
          secondary={secondary}
          enteredFields={enteredFields}
        />
      </StyledPaper>
    </StyledModal>
  );
};
