/* eslint-disable object-curly-newline */

import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { func, instanceOf } from 'prop-types';
import { DetailInput, DetailLabel, DetailSelect } from 'src/components/UI';
import { YesOrNotText } from 'src/constants/constants';
import { booleanToString, stringToBoolean } from 'src/utils/helpers';

import { styles } from './styles';

export const Overview = ({ values, handleChange, possibleValues }) => {
  const { touched } = useFormikContext();

  const handleBoolChange = (e) => {
    const {
      target: { value, name },
    } = e;

    handleChange({
      target: {
        name,
        value: stringToBoolean(value),
      },
    });
  };

  return (
    <Box>
      <Box sx={styles.requiredWrapper}>
        <span>*</span> Required
      </Box>
      <DetailLabel
        label={
          touched.relatesOutdoorProductTestingDescription ||
          !values?.relatesOutdoorProductTestingDescription?.length ? (
            <Box sx={styles.required}>
              Tell us about yourself, your recreation and travel, as it relates to outdoor product
              testing<span>*</span>
            </Box>
          ) : (
            <Box sx={styles.required}>
              About <span>*</span>
            </Box>
          )
        }
      >
        <DetailInput
          multiline
          name='relatesOutdoorProductTestingDescription'
          handleChange={handleChange}
          value={values.relatesOutdoorProductTestingDescription}
          submitOnValueChange
          placeholder='Tell us about yourself'
        />
      </DetailLabel>
      <DetailLabel
        label={
          <Box sx={styles.required}>
            Test Frequency <span>*</span>
          </Box>
        }
      >
        <DetailSelect
          label='Test Frequency'
          name='participationInTests'
          handleChange={handleChange}
          value={values.participationInTests}
          possibleValues={possibleValues.participationInTests}
          submitOnValueChange
          placeholder='Select frequency'
        />
      </DetailLabel>
      <DetailLabel
        label={
          <Box sx={styles.required}>
            Do you work for an outdoor company? <span>*</span>
          </Box>
        }
      >
        <DetailSelect
          label='if you work for an outdoor company'
          name='isWorkForAnOutdoorCompany'
          handleChange={handleBoolChange}
          value={booleanToString(values.isWorkForAnOutdoorCompany)}
          possibleValues={YesOrNotText}
          submitOnValueChange
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <DetailLabel label='What is your occupation?'>
        <DetailInput
          name='occupation'
          handleChange={handleChange}
          value={values.occupation}
          placeholder='Let us know what you do for a living'
          textTransform='unset'
          submitOnValueChange
        />
      </DetailLabel>
      <DetailLabel label='Do you have a dog?'>
        <DetailSelect
          label='if you have a dog'
          name='isHaveDog'
          handleChange={handleBoolChange}
          value={booleanToString(values.isHaveDog)}
          possibleValues={YesOrNotText}
          submitOnValueChange
          placeholder='Select yes or no'
        />
      </DetailLabel>
      <DetailLabel label='Do you recreate with kids?'>
        <DetailSelect
          label='if you recreate with kids'
          name='isRecreateWithKids'
          handleChange={handleBoolChange}
          value={booleanToString(values.isRecreateWithKids)}
          possibleValues={YesOrNotText}
          submitOnValueChange
          placeholder='Select yes or no'
        />
      </DetailLabel>
    </Box>
  );
};

Overview.propTypes = {
  handleChange: func,
  values: instanceOf(Object),
  possibleValues: instanceOf(Object),
};
